import React from 'react'
import '../../styles/pages/DataPageLogo.css'
export default ({imageSrc ,title,...rest}) => {
    
    return (
        <div {...rest} className='data-page-logo' >
        <img src={imageSrc}></img>
        <div className='separator'></div>
         
</div>
    )
}