import React,{useEffect} from 'react'
import '../styles/CatgorizeHiTechJobs.css'
export default () => {
    useEffect(() => {
        const categoriesList = document.getElementsByClassName("category-container");
        for (let index = 0; index < categoriesList.length; index++)
        {
            const currentCategory = categoriesList[index]
           
            const innerMenu = document.querySelector(`#${currentCategory.id}>.category-inner-menu`);
            const exitButton=document.querySelector(`#${currentCategory.id}>.category-title>.plus-icon`)
            currentCategory.addEventListener("click", (e) => {
                innerMenuToggle(e,exitButton,innerMenu)
            });
            exitButton.addEventListener("click", (e) => {
                innerMenuToggle(e,exitButton, innerMenu);
            });
          
            innerMenu.addEventListener("transitionend", (e) => {
                if(e.target.classList.contains("close"))
                    innerMenu.classList.add("take-out-from-flow")
            })
        }
    
    }, [])
    const innerMenuToggle = (e,exitButton,innerMenu) => {
        exitButton.classList.add("become-exit-button");
        if(innerMenu.classList.contains("close"))
        {
            innerMenu.classList.remove("take-out-from-flow")
            setTimeout(()=>{ innerMenu.classList.remove("close");},10)
           
        }
        else {
            exitInnerMenuEvent(e, innerMenu, exitButton)
        }
    }
    const exitInnerMenuEvent = (e,innerMenu,exitButton) => {
        e.stopPropagation();
        innerMenu.classList.add("close");
        exitButton.classList.remove("become-exit-button")
    }

    const getPluseIconSvg = () => {
        return(<svg className="plus-icon" xmlns="http://www.w3.org/2000/svg" id="Capa_1" viewBox="0 0 512 512" x="0px" y="0px" xml="http://www.w3.org/XML/1998/namespace" space="preserve" version="1.1">
        <g>
            <g>
                <path d="M 492 236 H 276 V 20 c 0 -11.046 -8.954 -20 -20 -20 c -11.046 0 -20 8.954 -20 20 v 216 H 20 c -11.046 0 -20 8.954 -20 20 s 8.954 20 20 20 h 216 v 216 c 0 11.046 8.954 20 20 20 s 20 -8.954 20 -20 V 276 h 216 c 11.046 0 20 -8.954 20 -20 C 512 244.954 503.046 236 492 236 Z" />
            </g>
        </g>
        </svg>)
    }
    return (
        <div className="categories-container">
           
            <ul className="category-list">
            <div id="programing-category" className="category-container">
            <div className='triangle triangle-left desktop-only'></div>
                    <li className="category-title">אבחון, יעוץ ומיון{getPluseIconSvg()}</li>
                    <div className="category-inner-menu close take-out-from-flow ">
                    <p>לאורך השנים גיבשנו תהליך מדעי לבדיקת התאמה לתחומי העיסוק השונים בענף ההיי-טק.</p>
                    <p>מועמדים רבים פונים אלינו לאחר שהתקבלו לקורסים בבתי ספר אחרים ומתאכזבים לקבל מאיתנו תשובה שלילית.
                    חשוב להבין כי אנחנו מרוויחים רק כאשר מועמד מתקבל לעבודה ולכן נדרשים לבחינה מעמיקה בהרבה שבוחנת את פוטנציאל המועמד לעמוד ב-2 הדרישות המרכזיות לעבודה בתפקידים בענף ההיי-טק:</p>
                    <p>א. פרופיל אישיותי מתאים.<br/>
                    ב. יכולת טכנית וידע מקצועי מעמיק.</p>
                    <p>בשלב ראשון תגישו מועמדות דרך האתר. לאחר מכן יתקיים ראיון טלפוני ראשון שלאחריו תתבקשו להשלים פרטים ונתונים נוספים הנדרשים לשלב המיון הראשון.
                        בשלב שני תקבלו מבדקי בית לפתרון בזמן מוגבל ומתואם מראש. 
                        בשלב שלישי תזומנו לראיון אישי עם מנהל ההדרכה ולמבדקי קבלה נוספים. 
                        אנחנו מעריכים את המועמדים שלנו ומתחייבים לחזור לכל מועמד עם תשובה.
                        
                    </p>
                    </div>
                   
            </div>
            <div id="web-category" className="category-container">    
                    <li className="category-title">מסלול הכשרה{getPluseIconSvg()}</li>
                    <div className="category-inner-menu close take-out-from-flow">
                        <p>למועמדים שיעברו בהצלחה את תהליכי האבחון ימונה מנהל תוכנית אישי. המנהל האישי ישב עם המועמד ויגדיר עימו מסגרת הכשרה מותאמת אישית. המסגרת תקבע בהתאם לשאיפות וההעדפות התעסוקתיות של המועמד, יכולת המועמד להתמקד אך ורק בלימודים או הצורך של המועמד ללמוד במסלול ערב במקביל להמשך עבודה במקום העבודה הנוכחי שלו ובהתאם לרקע הלימודי/אקדמי הקודם של המועמד וציוני בדיקות ההתאמה שלו.</p>
                        <p>כל מסלולי ההכשרה כוללים הן בסיס תיאורטי רחב, הן ידע טכני פרקטי והן פרוייקטים מעשיים.
                        ההכשרות השונות מועברות בתקופת הקורונה בלימודים מרחוק תוך שילוב טכנולוגיות מתקדמות יחודיות שפותחו לאורך השנים בהייפראקטיב - הכשרות פרונטליות רגילות, מנטורינג על ידי אנשי מקצוע מהתעשיה, פרוייקטים אישיים, סדנאות מיוחדות בקבוצות קטנות.</p>
                    </div>
                    <div className='triangle triangle-down desktop-only'></div>
            </div>
            <div id="embedded-category" className="category-container">    
                    <li className="category-title">תהליך השיבוץ{getPluseIconSvg()}</li>
                    <div className="category-inner-menu close take-out-from-flow">
                    <p>עם סיום ההכשרה, תעברו לטיפול מחלקת ההשמה - תעברו סדנת הכנה ואימון מתקדם לקראת ראיונות עבודה, תעברו סדרת ראיונות אצל לקוחות עד שתתקבלו לעבודה המיוחלת במקצוע אותו למדתם.
                    רק לאחר שנקלטתם בעבודה תסדירו את תשלום שכר הלימוד על הקורס אותו עברתם…
                    </p>
                    </div>
                    <div className='triangle triangle-right desktop-only'></div>
            </div>
            <div id="it-category"  className="category-container">    
                    <li className="category-title">קריירה חדשה{getPluseIconSvg()}</li>
                    <div className="category-inner-menu close take-out-from-flow">
                        <p>עשיתם את זה, נכנסתם לענף ההיי-טק.
                        אתם עובדים באחת מחברות ההיי-טק המובילות בישראל.
                        אנחנו עדיין עומדים לרשותכם לעזרה, ליתר בטחון.
                        אם תרצו גם נסייע לכם כסוכן אישי להמשיך לקדם אתכם בענף לתפקידים ומשכורות גבוהים יותר לאורך הקריירה.</p>
                    </div>
            </div>
            </ul>
        </div>
    )
}