import React,{useEffect} from 'react'
import LogoWidget from './DataPageLogo'
import ContactBox from '../FooterContact'
import ContactUs from '../ContactUs'

const title = ' קריירה בניהול רשתות ומחשוב';
export default () => {
        useEffect(() => {
                document.title=title
            },[])
        return (
                <div className="data-page-wrapper">
                <ContactUs className=' data-page-contact'/>

                        <div className="data-page" >
                    
                    <LogoWidget id='system-admin-data-page-logo' imageSrc={require('../../images/courses-icons/system_adminisration_course.png')} title={title}/>
    
                    <h1 className='main-page-title'>קריירה בניהול רשתות ומחשוב </h1>
                                <p className='data-section start-section '>
                                        ענף ההיי-טק צומח בקצב מהיר ומכיל מגוון גדול של תפקידים ומשרות ובכלל זה מפתחי ומהנדסי תוכנה, אנשי qa, מהנדסי חומרה, אנשי שיווק ומכירות, מעצבים, אנשי תמיכה ועוד.
                                                 <br />
                                        <br />
                                        ענף ההיי-טק צומח בקצב מהיר ומכיל מגוון גדול של תפקידים ומשרות ובכלל זה מפתחי ומהנדסי תוכנה, אנשי qa, מהנדסי חומרה, אנשי שיווק ומכירות, מעצבים, אנשי תמיכה ועוד.
                                        <br /><br />
                                        עם שהם הלכו והשתכללו מערכות ההפעלה העסקיות, שרתי הנתונים וטכנולוגיות הרשת, עד כדי כך שגם מהנדס תוכנה מנוסה איננו מסוגל לתחזקן ולנהלן ללא מעבר הכשרות ארוכות במיוחד בתחומים אלה.
                                        <br /><br />
                                        כתוצאה מכך קיים ביקוש גדול לשורה של תפקידים שנים במהותם תפקידי תמיכה וניהול טכניים בתחום ה- IT שנועדו לפתור בעיה זו.
                                        <br />
                                        <br />
                                        בחמש השנים האחרונות, עם השימוש הנרחב בטכנולוגיות ענן ווירטואליזציה גדלה יוקרת התחום וניכרת עליה משמעותית בדרגות השכר בתחום.
                                        </p>
                    <ContactBox className='mobile-only' />
                    <div className="data-page-content">
                <div className="positions-section data-section">
    <h2 className=' data-subtitle'>תפקידים בתחום</h2>
    <p>
                                                        בין שלל התפקידים הקיימים בתחום ה- IT ניתן לראות ביקוש משמעותי לטכנאי PC, תומכים טכניים, מנהלי רשתות תקשורת,  מנהלי מערכות מחשוב / system administrators,  מנהלי בסיסי נתונים/dba, מומחי ענן, ובראש הפירמידה - chief information officer.
                                                                                                             <br />
                                                        <br />
                                                        בניגוד לרוב התפקידים בענף ההיי-טק שקיים להם ביקוש רק בענף ההיי-טק, לעובדים בתחום ה-IT קיים ביקוש גדול גם בחברות היי-טק, וגם מחוץ לענף בכלל המשק הישראלי.
                                                </p> 
                    </div>
                    <div className="prerequisites-section data-section">
    <h2 className='data-subtitle'>קהל יעד ודרישות קדם</h2>
    <p>בעבר היתה תפיסה שגרסה שכל אחד יכול ללמוד את התחום, בהתאם בבתי ספר רבים אין שום ציפיות ודרישות מוקדמות מתלמידי התחום.
        <br />
                                                        <br />
                                                        בפועל זה ממש לא המצב.                                                
                                                </p>
    <p>    
    בלתי אפשרי לעבוד בתחום בלי כישורים וכישרון טכני. התשתיות השונות מתעדכנות באופן תדיר וללא שליטה טובה באנגלית בלתי אפשרי להשאר עם האצבע על הדופק. תשתיות הענן - הן אלה של מיקרוסופט, הן אלה של אמזון והן אלה של גוגל, מורכבות באופן יוצא דופן ודורשות יכולת לוגית ואינטלקטואלית טובה. כדי להתקדם לתפקידים הטכניים הבכירים נדרשת גם יכולת תכנות סבירה.
                                                </p>
    <p>    
    ברמות הניהוליות הגבוהות בתחום קיימת עבודה שוטפת מול גורמי הנהלה, והיעוץ חורג מהתחום הטכני גרידא ודורש יכולות ניתוח עסקי ופרזנטציה.
                                                </p>
    <p>    
                                                        בהתאם אנחנו בדרך כלל מצפים מהמועמדים להיות בעלי חוש טכני חזק, לשלוט באנגלית ולהיות בעלי יכולת לימוד עצמי טובה.
        <br /><br/>
        מועמדים אידיאליים יהיו גם בוגרי תואר ראשון, בעלי יכולת תקשורת ורבאלית וחשיבה מתמטית.
                                                        <br /><br />
                                                        מאחר שאנחנו עובדים בשיטת ״לא עבדת לא שילמת״ כל מועמד עובר תהליכי אבחון ומיון יסודיים בטרם קבלה למסלולי ההכשרה.
            </p> 
   </div>
                    <ContactBox className='mobile-only'/>
                    <div className="training-section data-section">
    <h2 className='data-subtitle'>מסגרת ההכשרות</h2>
    <p>
    מסלולי ההכשרה בניהול רשתות ומחשוב מועברים במסגרת לימודי בוקר, לימודי ערב ולימודים היברידיים, ונמשכים בין 7-14 חודשים.
    <br/>
    מסלולי ההכשרה כוללים סה״כ כ-540 שעות לימוד ותרגול אקדמאיות. 
    הלימודים מתקיימים בקצב מהיר וכוללים כמות גדולה מאוד של שיעורי בית, מבחנים, פרוייקטים ועבודות להגשה.
                                                            <br /><br/>
                                                            מלבד תעודת הסמכה מטעמנו, תלמידים המעוניינים בכך יכולים לגשת למבחני הסמכה של החברות מיקרוסופט, אמזון וסיסקו בתחום.  
    </p>  
    
       </div>
       <div className="prerequisites-section data-section">             
    <h2 className='data-subtitle'>נושאים עיקריים בהכשרות</h2>
    <ul className='subjects-list'>
    <li>מבוא לרשתות תקשורת</li>
    <li>מבוא למערכות הפעלה</li>
    <li>מבוא לתכנות</li>
    <li>מבוא ל - web</li>
    <li>שרתי microsoft</li>
    <li>שרתי linux</li>
    <li className='ltr-list-item'>cisco</li>
    <li>ענן azure של מיקרוסופט</li>
    <li>ענן aws של אמזון</li>
    <li >ענן gcp של גוגל</li>
    <li>אבטחת שרתים בענן</li>
    <li >תכנות בשפת python</li>
    
                            </ul>    
                                    </div>
                                    </div>
                        </div>
                        
        </div >)
}
