import React,{useState} from 'react'
import CostumInput from './CostumInput'
import PrivacyModal from './PrivacyModal'
import { handleForm,sendEmail } from '../utils/form'
import SendMailResultModal from '../Components/SendMailResultModal'
import AccessModal from '../Components/AccessModal'
import '../styles/ContactUs.css'
export default ({className}) => {
    const [isPrivacyModalHidden, setIsPrivacyModalHidden] = useState(true)
    const [isAccessModalHidden, setIsAccessModalHidden] = useState(true)
    const [isMailSendModalShown,setIsMailModalShown]=useState(false)
    const [isSendFormSuccess,setSendFormSuccess]=useState()
    const handleSubmit = async (e) => {
        const form = e.target.cloneNode(true);
        const isFormValid = await handleForm(e);
        if(isFormValid)
        {
           
            setSendFormSuccess(await sendEmail(form))
            setIsMailModalShown(true)
            form.reset();
        }
    }
    return (
        <div className={`contact-container ${className} ${!isPrivacyModalHidden||!isAccessModalHidden?'inside-modal-open':''}`}>
            <SendMailResultModal closeCallBack={setIsMailModalShown} isSuccess={isSendFormSuccess} hidden={!isMailSendModalShown}/>
    
    <form onSubmit={handleSubmit}>
                <div className="input-container">
                <h2>?רוצה לשמוע עוד</h2>
        <CostumInput placeholder='*נא להזין שם תקין' text='שם מלא' name="name"/>
        <CostumInput placeholder='*נא להזין מספר טלפון' text="טלפון" name='phone' />
            <CostumInput placeholder='*נא להזין כתובת מייל' text="אי-מייל" name='email' />
            </div>
            <div className="agree-checkbox">
                    <label className="agree-explain">
                        אני מאשר קבלת עדכונים ומבצעים מהייפראקטיב בכפוף לתקנון.
                        </label>
                <input defaultChecked={true} className="checkbox" type="checkbox" name='agree' value='agree promote'/> 
            </div>    
                <a onClick={(e) => setIsPrivacyModalHidden(false)} className="privacy-policy">מדיניות פרטיות משתמשים</a>
                <a onClick={()=>setIsAccessModalHidden(false)} className="privacy-policy">הצהרת נגישות</a>
        <button className="sumbit-contact">
             <div className="sumbit-contact-inner-box">!דברו איתי</div>
        </button>
            </form>
            <div className="absolute-wrapper">
            <PrivacyModal hidden={isPrivacyModalHidden} closeCallBack={setIsPrivacyModalHidden} />
                <AccessModal hidden={isAccessModalHidden} closeCallBack={setIsAccessModalHidden} />
                </div>
</div>)
}