import React,{useEffect} from 'react'
import LogoWidget from './DataPageLogo'
import ContactBox from '../FooterContact'
import ContactUs from '../ContactUs'

const title = 'קריירה בפיתוח מערכות משובצות מחשב (embedded)';
export default () => {
        useEffect(() => {
                document.title=title
            },[])
        return (
                <div className="data-page-wrapper">
                <ContactUs className=' data-page-contact'/>

                        <div className="data-page" >
                    
                    <LogoWidget id='embedded-data-page-logo' imageSrc={require('../../images/courses-icons/embedded_course.png')} title={title}/>
    
                    <h1 className='main-page-title'>קריירה בפיתוח מערכות משובצות מחשב (embedded)</h1>
                                <div className='data-section start-section '>
                                <p className='section-emphasis'>
                                קורסי הפיתוח שלנו הינם הטובים בישראל. הקורסים מיועדים עבור מועמדים שמעונינים בהכשרה יסודית וכניסה מהירה לעבודה בפיתוח תוכנה בענף ההיי-טק.
                                                                     <br />
                                <br />
                                עם זאת, אם אתה מוכן לעבור הכשרה ארוכה יותר, ומוכן להתחייב מראש לעבוד מספר שנים כמפתח תוכנה,
                                "<a href='https://talpiot-hitech.com/' target='_blank'  className='underline-emphasis inline-item'>תלפיות היי-טק</a>"
                                עדיפה עבורך. תלפיות היי-טק מציעה תוכנית הכשרה ארוכה ומעמיקה מאוד של אנשי פיתוח, במגוון גדול של טכנולוגיות ובמודל בו התלמידים לא משלמים כלל שכר לימוד ומתחייבים לעבוד עבור החברה מספר שנים.
                        </p>
                                        <p>
                                                מכל מקצועות ההיי-טק אין ספק שהמקצוע המאתגר ומתגמל ביותר הן כספית והן מבחינת מסלול קריירה הינו מקצוע מפתח התוכנה.
                                                         <br />
                                                         ללא קשר לכינוי שניתן למשרות על ידי חברות שונות - ״תוכניתן״, ״איש פיתוח״, ״מהנדס תוכנה״, ״ארכיטקט תוכנה״ וכו׳ בבסיס מפתח התוכנה עוסק בכתיבת תוכניות מחשב שנועדו לממש אלגוריתמים לפתרון מגוון בעיות בחיים המעשיים.
                                        </p>
                                        <p>
                                                קיים מגוון גדול של התמחויות בפיתוח תוכנה, כאשר אחת ההתמחויות הותיקות הינה פיתוח תוכנה למערכות משובצות מחשב (סביבת embedded‪ ‬). 
                                        </p>
                                        <p>
                                                מערכות משובצות מחשב שונות מאוד מהדימוי הנפוץ של מערכות מחשב שיש לציבור. מערכות משובצות מחשב הינן מוצרים ממגוון גדול מאוד - החל מצעצועים, מכשירי חשמל, מטוסים ומכוניות ועד נשק מתקדם וטילים. במוצרים אלה מוטמעת טכנולוגית מחשב ותוכנה לצורך תפעול ובקרה שוטפת.
                                        </p>
                                        <p>
                                                מונח נוסף שנשמע לרוב בקשר לפיתוח לסביבת embedded הינו ״פיתוח real time״. תוכנה שיש לה ‪“‬דרישות לעבודה ב-real time״ הינה תוכנה שמהירות החישוב שלה קריטית למימוש מטרת המוצר בו הינה מוטמעת. 
                                        <br />
                                        ברור שכולנו נשמח אם הדפדפן שלנו יהיה מהיר יותר, אבל גם אם נצטרך להמתין עוד מספר אלפיות שניה לטעינת דף שום דבר מהותי לא יקרה. לעומת זאת טיל שמערכת הביות שלו תתעכב באלפית שניה עשוי לפספס את המטרה…        
                                        </p>
                                        <p>
                                        בניגוד לפיתוח ל-web ל-mobile ולענן שמשתמשים בטכנולוגיות, שפות התכנות והכלים החדשים ופורצי הדרך, תחום הפיתוח ל-embedded הוא אחד השמרניים ביותר בתחום. שפות התכנות העיקריות בהן נעשה שימוש הינן שפת c הותיקה שהופיעה לראשונה ב-1972 ושפת c‪++‬ שהופיעה לראשונה ב-1981 . תהליכי הפיתוח בתחום שלובים עם תהליכי פיתוח החומרה והמוצרים הפיזיים ולכן בדרך כלל פיתוח ל-embedded מבוצע בחברות גדולות, בתהליכים ממסדיים ואיטיים יחסית. מצד שני, לאור אופי המעסיקים ותהליכי הפיתוח, עבודה ב-embedded מקנה לרוב יציבות תעסוקתית והתחום פחות רגיש למשברים כלכליים ומהפכות טכנולוגיות.
                                        </p>
                    <ContactBox className='mobile-only' />
                    <div className="data-page-content">
                <div className="positions-section data-section">
    <h2 className=' data-subtitle'>תפקידים בתחום</h2>
    <p>
    התפקיד הראשון בתחום הינו מפתח תוכנה. מפתחי תוכנה מתחילים (junior) בתחום עובדים בדרך כלל בצוות של 4-5 מפתחים תחת ראש צוות. ברוב החברות קיימים מסלולי קידום מקצועיים וניהוליים כאחד. לאחר מספר שנים בתור מפתח senior מקבלים לרוב אחריות מוגברת ועצמאות גבוה יותר בעבודה  או הופכים לראש צוות. בהמשך ניתן להתקדם לניהול מספר צוותים, ראש מחלקה ולבסוף מנהל פיתוח. אנשים בעלי נטיות עסקיות נודדים לאחר מספר שנות פיתוח למגוון תפקידים עסקיים בחברות הגדולות, הדורשים גם רקע והבנה טכנולוגית מעמיקה.    </p> 
                    </div>
                    <div className="prerequisites-section data-section">
    <h2 className='data-subtitle'>קהל יעד ודרישות קדם</h2>
    <p>‬
    כלל תפקידי פיתוח התוכנה דורשים יכולת לוגית גבוה, חשיבה מתמטית טובה מאוד ואנגלית מצויינת.    </p>
    <p>    
    תחום ה-embedded שמרני מאוד גם בטכנולוגיות אך גם באופן גיוס המועמדים. בהתאם גם מועמד מצוין שאין ספק שיהיה נכס לארגון, יתקשה מאוד להתקבל לעבודה ללא תואר אקדמי.    </p>
    <p>    
    בהתאם, הרוב המוחלט של התלמידים במסלול ה-embedded אצלנו הינם בעלי תואר ראשון בהצטיינות+ציון פסיכומטרי 680 ומעלה.    </p>
    <p>    
    אם אין לך את הנתונים האלה אבל אתה מאמין ביכולות שלך ובעל רצון עז להיות מפתח תוכנה, הגש מועמדות בכל מקרה.
                                                                <br />
                                                                אנחנו עובדים, כידוע, בשיטת ״לא עבדת לא שילמת״ וכל מועמד עובר תהליכי אבחון ומיון יסודיים בטרם קבלה למסלולי ההכשרה. אם תעבור אותם בהצלחה, הנתונים הסטטיסטיים הראשונים שלך לא ישנו.    </p> 
   </div>
                    <ContactBox className='mobile-only'/>
                    <div className="training-section data-section">
    <h2 className='data-subtitle'>מסגרת ההכשרות</h2>
    <p>
    מסלול ההכשרה בפיתוח תוכנה לסביבת embedded  מועבר במסגרת לימודי בוקר אינטנסיביים, לימודי ערב ולימודים היברידיים, ונמשך בין 4-12 חודשים.
                                                                <br />
                                                                מסלול ההכשרה כולל כ- 500 שעות לימוד ותרגול אקדמאיות.
                                                                <br />
                                                                הלימודים מתקיימים בקצב מהיר וכוללים כמות גדולה מאוד של שיעורי בית, מבחנים, פרוייקטים ועבודות להגשה.
                                                        </p>  
    
       </div>
       <div className="prerequisites-section data-section">             
    <h2 className='data-subtitle'>נושאים עיקריים בהכשרות</h2>
    <ul className='subjects-list'>
    <li>מבוא לפיתוח תוכנה</li>
    <li>מבוא למערכות הפעלה</li>
    <li>מבוא לרשתות תקשורת</li>
    <li>מבני נתונים</li>
    <li>אלגוריתמים</li>
    <li>סיבוכיות</li>
    <li className='ltr-list-item'>Linux internals</li>
    <li>מסדי נתונים ושפת SQL </li>
    <li className='ltr-list-item'>Bash</li>
    <li className='ltr-list-item'>C</li>
    <li >תכנות מבני</li>

    <li className='ltr-list-item'>C++</li>
    <li >תכנות מוכוון עצמים</li>
    
    <li className='ltr-list-item'>Multi paradigm programming</li>
    <li className='ltr-list-item'>UML</li>
    <li>אופטימיזציה ו-Profiling‬</li>
                            </ul>    
                                    </div>
                                    </div>
                        </div>
                        
                        </div ></div>
        )
}
