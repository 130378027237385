import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router/Router'
import MainPage from './Components/MainPage'
import '../src/styles/sheard.css'
import ContactUs from './Components/ContactUs';
ReactDOM.render(

    <Router />
,
  document.getElementById('root')
);


