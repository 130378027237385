import React from 'react'
import NavigationBar from '../Components/NavigationBar'
import MainPage from '../Components/MainPage'
import CyberPage from '../Components/Pages/CyberPage'
import NotFoundPage from '../Components/NotFound'
import history from './history'
import '../styles/Router.css'

import {BrowserRouter, Link, Switch, Route, Router } from "react-router-dom";
import WhatsappWidget from '../Components/WhatsappWidget'
import StickyContact from '../Components/StickyContact'
import FooterContact from '../Components/FooterContact'
import SystemAdminPage from '../Components/Pages/SystemAdminPage'
import MarketingPage from '../Components/Pages/MarketingPage'

import QaPage from '../Components/Pages/QaPage'
import UiUxPage from '../Components/Pages/UiUxPage'
import FullStackPage from '../Components/Pages/FullStackPage'
import EmbeddedPage from '../Components/Pages/EmbeddedPage'
import MobilePage from '../Components/Pages/MobilePage'
import ScrollToTop from './ScrollToTop'
export default () => {
    return (
      <div>
            <BrowserRouter  history={history}>
           <ScrollToTop/>
                    <NavigationBar />
                   
             
            <Switch>
                <Route path='/' exact  component={MainPage}/>
                    <Route path='/cyber-page'  component={CyberPage} />
                    <Route path='/system-admin-page' component={SystemAdminPage}/>
                    <Route path='/marketing-page' component={MarketingPage}/>
                    <Route path='/qa-page' component={QaPage}/>
                    <Route path='/ui-ux-page' component={UiUxPage} />
                    <Route path='/fullstack-page' component={FullStackPage} />
                    <Route path='/embedded-page' component={EmbeddedPage} />
                    <Route path='/mobile-page' component={MobilePage} />
                    <Route component={MainPage} />
                </Switch>
                <FooterContact/>
            <WhatsappWidget />
            <StickyContact/>
            </BrowserRouter>
            
         </div>
    )
}