import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/CourseWidget.css'
export default (props) => {
    return (
        <Link id={props.id} to={props.href} className="course-container">
            <div className="course-logo">
                <img src={props.logoSrc} class="course_img"></img>
            </div>
            <div className="course-separetor"></div>
            <div className="course-name">{props.title}</div>
            <div className="arrow-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM25,13.75a1.26,1.26,0,0,0-1.25-1.25H14l3.69-3.69a1.23,1.23,0,0,0,0-1.76L15.86,5.27A1.24,1.24,0,0,0,15,4.92a1.26,1.26,0,0,0-.88.35L7,12.34,5.25,14.12a1.28,1.28,0,0,0,0,1.76L7,17.66l7.07,7.07a1.26,1.26,0,0,0,.88.35,1.24,1.24,0,0,0,.88-.35L17.64,23a1.28,1.28,0,0,0,0-1.76L14,17.5h9.8A1.26,1.26,0,0,0,25,16.25Z" />
                </g></g><defs></defs><title>arrow</title><script xmlns="" /></svg>
            </div>
        </Link>
    )
}