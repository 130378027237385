import React from 'react'
export default (props) => {
    
    return (
    <div {...props} className={`privacy-modal`}>
    <div className='modal-privacy-content'>
      
        <i onClick={(e)=>props.closeCallBack(true)} class="fas fa-times exit-button"></i>
        
        <h3>הצהרת נגישות</h3>

      
        <h6>נגישות אתר האינטרנט</h6><br/>
        אתר אינטרנט נגיש הוא אתר המאפשר לאנשים עם מוגבלות ולאנשים מבוגרים לגלוש באותה רמה של יעילות והנאה ככל הגולשים, כ- 20 עד 25 אחוזים מהאוכלוסייה נתקלים בקשיי שימוש באינטרנט ועשויים להיטיב מתכני אינטרנט נגישים יותר, כך על פי מחקר שנערך בשנת 2003 ע"י חברת מייקרוסופט. אנו מאמינים ופועלים למען שוויון הזדמנויות במרחב האינטרנטי לבעלי לקויות מגוונות ואנשים הנעזרים בטכנולוגיה מסייעת לשימוש במחשב. 
        <br />
                <br/>
<h6>מידע על נגישות האתר</h6><br/>
●	אתר זה עומד בדרישות תקנות שיוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות), התשע"ג 2013.<br/>
●	התאמות הנגישות בוצעו עפ"י המלצות התקן הישראלי (ת"י 5568) לנגישות תכנים באינטרנט ברמת AA ומסמך WCAG2.0 הבינלאומי.<br/>
            <span className='underline-text'>תיקונים והתאמות שבוצעו</span>
            <div className='margin-10r'>
○	אמצעי הניווט באתר פשוטים וברורים.<br/>
○	תכני האתר כתובים באופן ברור, מסודר והיררכי. במידת הצורך קיימים הסברים לצד התוכן.  <br/>
○	האתר מותאם לצפייה בדפדפנים מודרנים.<br/>
○	התאמת האתר לתצוגה תואמת מגוון מסכים ורזולוציות.<br/>
○	תוכן האתר כתוב בשפה פשוטה וברורה.<br/>
○	כל הדפים באתר בעלי מבנה קבוע.<br/>
○	לכל התמונות באתר יש הסבר טקסטואלי חלופי (alt).<br/>
○	האתר מאפשר שינוי גודל הגופן על ידי שימוש במקש Ctrl וגלגלת העכבר.<br/>
○	אין באתר שימוש בתצוגת טקסט נע או מהבהב.<br />
</div>
<br/>
●	הבדיקות נבחנו לתאימות הגבוהה ביותר עבור דפדפן google chrome.<br/>
●	האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים וחלונות.<br/>
●	מותאם לתצוגה בדפדפנים הנפוצים ולשימוש בטלפון הסלואלרי.<br/>
●	לשם קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים לשימוש בתוכנת NVDA העדכנית ביותר.<br/>
       <br/>  
<h6>יצירת קשר בנושא נגישות </h6><br/>
אם נתקלתם בבעיה בנושא נגישות באתר, נשמח לקבל הערות ובקשות, באמצעות פנייה לאחראי הנגישות באתר בדואר האלקטרוני accessibility@hyperactive.co.il. <br/>
כדי שנוכל לטפל בבעיה בדרך הטובה ביותר, אנו ממליצים מאוד לצרף פרטים מלאים ככל שניתן:<br/>
●	תיאור הבעיה.<br/>
●	מהי הפעולה שניסיתם לבצע.<br/>
●	קישור לדף בו גלשתם.<br/>
●	סוג הדפדפן וגרסתו.<br/>
●	מערכת הפעלה.<br/>
●	סוג הטכנולוגיה המסייעת (במידה והשתמשתם).<br/>
<br/>
<h6>פרסום הצהרת הנגישות</h6><br/>
הצהרת הנגישות עודכנה ביום: 1.11.2020        <br/><br/><br/><br/><br/>
    </div>
</div>)
}