import React, { useEffect ,useState} from 'react'

import NavigationBar from './NavigationBar'
import CourseGrid from './CoursesGrid'
import ContactUs from './ContactUs'
import { handleForm, sendEmail } from '../utils/form'
import SendMailResultModal from '../Components/SendMailResultModal'

import Banner from './PromoteBanner'
import '../styles/MainPage.css'
import QuestionAnswerNode from './QuestionAnswerNode'
import HowItWorks from '../Components/CatgorizeHiTechJobs'
import Modal from './Modal'


export default (props) => {
    const [isMailSendModalShown,setIsMailModalShown]=useState(false)
    const [isSendFormSuccess, setSendFormSuccess] = useState();
    const [answersOpen, setAnswersOpen] = useState([]);
    const handleSubmit = async (e) => {
        const form = e.target;
       
        const isFormValid = await handleForm(e);
        if(isFormValid)
        {
            const sendMailSuccess=await sendEmail(form)
            setSendFormSuccess(sendMailSuccess)
            
            setIsMailModalShown(true)
            form.reset();
            return {
                validate: true,
                isSendEmail:sendMailSuccess}
        }
        else
        return {
            validate: false,
            isSendEmail:false
        }
    }
    const setAnswerOpenById = (id) => {
        
        setAnswersOpen('');
        setTimeout(() => {
            setAnswersOpen(id);
        }, 10);
        
       
    }
   
    useEffect(() => {
        document.title = 'הייפראקטיב - בית הספר להיי-טק הטוב בישראל';
        
    }, [])
   
    return (
        <div className="main-page-container">
            
            <Modal handleSubmit={handleSubmit} />
            <SendMailResultModal hidden={!isMailSendModalShown} closeCallBack={setIsMailModalShown} isSuccess={isSendFormSuccess}/>
            <Banner/>
            <div className="flex-col-wrapper">
            <div className="cream-bg sub-banner">
            <span className='sub-banner_text'> אבחון, יעוץ ומיון מקצועי בשווי 1000 ש״ח על חשבוננו</span>
        </div>
            <div className="main-section">
                
                
                <div className="courses-explanation-links">
                    
                   
                <h1 className="courses-title">מה הקריירה שלך בהיי-טק?</h1>
                    <CourseGrid />
                </div>
                    <ContactUs />
                    </div>
            </div>
            <div id="pros-section" className="pros-section">
            <h2 className="pros-section-title">למה ללמוד אצלנו?</h2>
                <div className="section-container ">
                

            
                        <div className='section-text'>
                        
                <p className="pro-text">יש הרבה סיבות מדוע ללמוד אצלנו ומדוע אנחנו בית הספר להיי-טק הטוב בישראל. אבל בסופו של דבר הכל נובע מסיבה אחת פשוטה:</p>
                <p className="pro-text"><strong>אתם רוצים לפתוח קריירה בענף ההיי-טק והולכים ללמוד קורס רק מסיבה אחת - להתקבל לעבודה!
                </strong></p>
                <p className="pro-text blue-emphsis">אנחנו בית הספר היחיד שמרוויח כסף  <strong>רק ואך ורק</strong> אם אכן תתקבלו לעבודה.</p>
                </div>
                        
           
        
            <div className="differences-section">
                <div className="difference other-places">
                    <p>
                    <strong>בשאר השוק </strong>- תגיעו להתעניין בלימודים ותקבלו מאנשי המכירות יחס נפלא, תשמעו  <a href='#public-question' onClick={(e)=>{setAnswerOpenById('#how-much-salary')}} className='paragraph-link'>הבטחות מהממות</a>, ויופעלו עליכם <a href='#public-question' onClick={(e)=>setAnswerOpenById('#work-promise-question')} className='paragraph-link'>תרגילים שיווקיים מכל הבא ליד כדוגמת ״התחייבות לעבודה״ חסרת כל משמעות</a>,  ירשמו אתכם לקורס ויגבו מכם מראש שכר לימוד מלא. 
                    מרגע זה בגדול בית הספר עשה את שלו -  תצליחו, לא תצליחו, תמצאו עבודה, לא תמצאו עבודה זה כבר פחות משנה.
                    בהתאם ברוב בתי הספר, למרות מאמצים וכוונות טובות בדרך כלל, מתגלות הבעיות הבאות:</p>
                    <ol>
                        <li>לא קיים תהליך אבחון ומיון אמיתי - ״יועצי לימודים״ הינם בעצם אנשי מכירות, המתוגמלים על פי כמות הנרשמים, ובהתאם רושמים המון מועמדים שאין להם מראש סיכוי אמיתי להצליח בקורסים ולהתקבל לעבודה בסיום ההכשרה.</li>
                        <li>קורסים הכוללים הרבה מועמדים חלשים מתנהלים בקצב איטי. כתוצאה מכך למרות כמות שעות לימוד גדולה, ״על הנייר״, מספיקים לעבור על הנושאים בצורה שטחית בלבד. </li>
                        <li>ללא קשר לכישורי המדריכים ״על הנייר״, רוב המדריכים משתכרים נמוך יחסית למקובל בענף ההיי-טק ואיכות ההוראה נפגעת בהתאם.</li>
                        <li>בסיום ההכשרה גוף ההשמה המצומצם יוצא ידי חובה בפעילות מועטה, מול הבוגרים.</li>
                      
                    </ol>
                    <p>
                    כתוצאה מכך, ברוב בתי הספר , בדרך כלל כמחצית התלמידים נושרים מהקורס עד שהקורס מסתיים, וכמות הבוגרים שמצליחים להשתלב בתעשיית ההיי-טק במקצוע אותו למדו בקורס נמוכה מאוד.

לצערנו, בעידן הקורונה, מסתיים לעיתים קורס מבלי שאפילו בוגר אחד מוצא עבודה בתפקיד אליו הוכשר...
                    </p>
                </div>
                <div className="difference at-ours">
                    <p> <strong>אצלנו </strong>לעומת זאת הכל הפוך -  אם לא תתקבלו לעבודה בתחום אותו למדתם אצלנו לא תשלמו בכלל שכר לימוד!  </p>
                    <p>בהתאם הדברים מתנהלים בצורה הבאה:</p>
                    <ol>
                        <li>כשתפנו אלינו תעברו תהליך אבחון, יעוץ ומיון מקצועי אמיתי. לצערנו  רוב המועמדים מקבלים תשובה ישירה וכנה שהתחום לא מתאים להם, שלא נקבל אותם לקורס וחבל על בזבוז הזמן שלהם.</li>
                        <li>רק מועמדים שאנחנו מאמינים שיש להם סיכוי טוב לסיים את הקורסים בהצלחה ולהתקבל לעבודה - מתקבלים לקורסים. בהתאם הקורסים מתנהלים ברמה גבוה ובקצב מהיר והנושאים נלמדים  יסודית ומעמיקה.</li>
                        <li>צוות ההדרכה ושיטות ההדרכה הינן המתקדמות והחדשניות ביותר שכן המטרה העליונה שלנו להכשיר את המועמדים ברמה הגבוה ביותר.</li>
                        <li>עם סיום ההכשרה נכנס לפעולה מנגנון ההשמה המקצועי והמתוקצב היטב שלנו ורק לאחר שתלמיד מתקבל לעבודה בתחום אותו למד, אז ורק אז, סוף סוף אנחנו מקבלים את גמולנו - תשלום עבור ההכשרה!</li>
                    </ol>
                    </div>
                    </div>
                    </div>
            </div>
            <div id='graduate-section' className="graduate-companys">
                <div className="section-container white-bg">
                <h2>כאן תמצאו את הבוגרים</h2>
                <div className="firms-container">
                    <img src={require('../images/firms_logos/att.png')} alt="AT&T" className="firm-logo"/>
                    <img src={require('../images/firms_logos/allot-logo_tagline.svg')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/avg.jpg')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/checkpoint.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/cisco .jpg')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/Comverse.svg.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/emet.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/gini apps.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/globalbit.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/guliver logo.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/linnovate.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/HP.svg.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/iai.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/ibm.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/idigital.jpg')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/intel.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/ironsource.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/Liveperson.webp')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/dxc.jpg')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/matrix.jpg')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/moblin.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/ness logo.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/nice.jpg')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/ono apps.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/Orbotech.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/Pelephone-logo.svg.png')} alt="" className="firm-logo"/>
                    <img src={require('../images/firms_logos/whooplay logo.png')} alt="" className="firm-logo"/>

                    <img src={require('../images/firms_logos/binat.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/3fish.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/airsoft.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/amdocs.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/bambi.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/bezeq.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/bigapps.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/cardlatch.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/devsense.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/commit_systems.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/ebay logo.jpg')} className='firm-logo' />
                    <img src={require('../images/firms_logos/emc.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/fieldbit.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/globalbit.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/hidden flights.webp')} className='firm-logo' />
                    <img src={require('../images/firms_logos/ideo.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/inmanage.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/Intuition-Robotics-logo.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/iseeya.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/klal.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/lemonade .png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/log on.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/malamteam.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/Matrix_logo.jpg')} className='firm-logo' />
                    <img src={require('../images/firms_logos/microsoft.jpg')} className='firm-logo' />
                    <img src={require('../images/firms_logos/moblin.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/ngsoft.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/Omnitelecomlogo-01.svg')} className='firm-logo' />
                    <img src={require('../images/firms_logos/pocketlink.jpg')} className='firm-logo' />
                    <img src={require('../images/firms_logos/qualitest.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/quickkode.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/round robin.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/round trip logo.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/sandisk.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/SYTE-MAIN-LOGO-MOBILE.svg')} className='firm-logo' />
                    <img src={require('../images/firms_logos/testpro.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/travelist.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/via.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/wix.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/yael.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/zap.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/zigit.png')} className='firm-logo' />
                    <img src={require('../images/firms_logos/בנק הפועלים.jpg')} className='firm-logo' />
                    <img src={require('../images/firms_logos/בנק לאומי.jpg')} className='firm-logo' />

                    </div>
                </div>
            </div>
            <div id='about-us-section' className="about-us-section">
                <div className='section-container'>
                <h2>קצת עלינו</h2>
                    <p>הייפראקטיב הוקמה ב- 2004 על ידי צוות בראשות טל שחור, יזם היי-טק ותיק.<br />
                        <br/>
                צוות ההדרכה בחברה הינו מהוותיקים והמנוסים בישראל.  חלקנו הקמנו בעבר בתי ספר להיי-טק אחרים, חלקנו עבדנו בתפקידי ניהול, שיווק והדרכה בבתי ספר אחרים.
                </p>
                    <p>
                        לאורך השנים הכשרנו אלפי עובדים לענף ההי-טק בכל התמחות ומיומנות אפשרית.
                        <br />
                        <br/>
                        לאורך השנים היינו תמיד חדשניים - היינו הראשונים ללמד באופן מקצועי טכנולוגיות חדשות.
                </p>
                <p>
                בית הספר היחיד שפיתח מערכות יעודיות ואימץ באופן מלא  שיטות הדרכה מתקדמות מרחוק -  שנים לפני שמישהו שמע על נגיף הקורונה.
                </p>
                    <p>
                    <strong>בשנות הפעילות הראשונות שלנו</strong>, העברנו קורסי הכשרה במודל המקובל בשוק - קורסים במגוון תחומים גדול המקבילים לקיים בשוק  ובתשלום מראש.
                    <br />
                        <br/>
                    <strong>לאחר מכן הקמנו את מיזם ״תלפיות היי-טק״</strong>. תלפיות היי-טק מתמקד בהכשרה ארוכה ומעמיקה מאוד של אנשי פיתוח,במגוון גדול של טכנולוגיות ובמודל בו התלמידים לא משלמים כלל שכר לימוד ומתחייבים לעבוד אצלנו מספר שנים. 
                    <br />
                        <br />
                        <strong>כעת, לאור התקופה הקשה העוברת על המשק הישראלי</strong>, כאשר מאות אלפי מובטלים מחפשים כיוון מקצועי ונושאים עיניהם אל תעשיית ההיי-טק, החלטנו להטות כתף, להיות החלוצים <strong>ולהפוך לבית הספר היחיד, שמציע מסגרות הכשרה חסרות סיכון במודל ״לא עבדת לא שילמת״</strong>.
                    </p>
                    <p>
                        הייפראקטיב הינה ״מכללת בוטיק״ - אנחנו סלקטיביים מאוד ומטפלים בכמות מצומצמת של מועמדים שמשכנעים אותנו שיש להם את מה שנדרש כדי לפתח קריירה משגשגת בענף ההי-טק.
                    <br />
                        <br />
                        אנו מלווים באופן צמוד את הבוגרים גם לאחר שהתקבלו לעבודה לאורך הקריירה שלהם - בעצות טובות, הפעלת קשרים כשנדרש ושרותי סוכן אישי צמוד - אנו רואים בבוגרינו משאב רב ערך לתעשית ההיי-טק והמשק הישראלי והם מקבלים את מלוא התמיכה לה הם ראויים בהתאם.    
                    </p>
                </div>
                </div>
            <div id='how-it-work-section' className="how-it-work-section">
            
                <div className="section-container how-it-work-main-content">
                <h3 className="title">איך זה עובד</h3>
                <div className="how-it-work-explain">
              
                <HowItWorks />
                </div>
                </div>
                </div>
                <div id='quations-answers-section' className="quations-answers-section">
                
                <div className="section-container">
                    <h2>שאלות שכנראה תשאלו</h2>
                <div className='section-inner-container'>
                <div className='quations-answers-container'>
                <QuestionAnswerNode question='מה זאת אומרת לא עבדת? לא שילמת!'
                    answer='מאוד פשוט, ללא אותיות קטנות, רק כשתתקבל לעבודה תשלם על הקורס שלמדת.
                לא התקבלת לעבודה? לא שילמת לנו שקל.'/>
                <QuestionAnswerNode
                 question='אני רוצה ללמוד אבל אין לי כסף, מה לעשות?'
                    answer='הכל בסדר. אצלנו זוֹ לא בעיה. לא תצטרכו לשלם מראש. לא תצטרכו לקחת הלוואה. שום דבר
                    תשלמו על הלימודים רק אחרי שתתחילו לעבוד וגם אז התשלום יתבצע בתשלום חודשי קטן קבוע.'/>
                
                <QuestionAnswerNode
                 question='איפה יש לכם סניפים/ איפה אתם מלמדים?'
                    answer='בתקופת הקורונה אנחנו מלמדים בכל מקום בארץ on line  באמצעות שיטות הדרכה מתקדמות.'/>
                
                <QuestionAnswerNode
                 question='מתי מתקיימים מיונים לקורסים השונים?'
                    answer='מיונים מתקיימים כל הזמן באופן שוטף.'/>
                
                <QuestionAnswerNode
                 question='מתי מתחילים קורסים?'
                    answer='קורסים מתחילים לאורך כל השנה באופן שוטף. בדרך כלל, מועמדים שעברו את תהליכי המיון בהצלחה, לא צריכים להמתין יותר מ-3-4
                    שבועות לתחילת ההכשרה. '/>
                
                <QuestionAnswerNode
                 question='האם ניתן לעבוד תוך כדי ההכשרות?'
                    answer='ניתן לעבוד תוך כדי ההכשרות אם בוחרים במסלול ערב.'/>
                </div>
                <div className='quations-answers-container'>
                <QuestionAnswerNode
                 question='איך אני יכול לדעת איזה מקצוע בהיי-טק מתאים לי?'
                    answer='הגש מועמדות באמצעות הטופס ונתחיל בתהליך האבחון, היעוץ והמיון המקצועי שלנו ללא כל עלות וללא כל מחויבות.
                    נחזיק לך אצבעות שתמצא מתאים לאחד המקצועות בענף ההיי-טק!'/>
                
                <QuestionAnswerNode
                 question='סיימתי קורס בבית ספר אחר ולא מצאתי עבודה, מה אני יכול לעשות?'
                    answer='
                    קודם כל אתה יכול להתנחם בכך שאינך יוצא דופן, חיפוש קצר בקבוצות פייסבוק יגלה לך שרוב בוגרי הקורסים נקלעים לאותה סיטואציה שאתה הגעת אליה.
                    <br/>
                    <br/>
                        יש 3 אפשרויות למה סיימת קורס בבית ספר אחר ולא מצאת עבודה:
                        <ol>
                            <li>‫הקורס שלמדת לא היה מקיף ו/או לא הועבר ברמה הנדרשת.</li>
                           <li>‬אתה לא מתאים לתחום אליו קיבלו אותך ללימודים.</li>
                            <li>אתה לא מתאים מבחינת הפרופיל האישיותי למקצוע שלמדת ו/או ״לא יודע איך לעבור ראיון״.</li>
                        </ol>         
                        <br />
                        הגש אלינו מועמדות באמצעות הטופס, נתחיל בתהליך האבחון, היעוץ והמיון המקצועי שלנו ללא כל עלות וללא כל מחויבות.
                        בסיום התהליך נוכל לתת לך תשובה מדויקת מה הסיבה שלא התקבלת לעבודה.
                    <br/>
                        במידה ויתברר שאתה מתאים לתחום אותו למדת ובעל פרופיל אישיותי מתאים, נתאים לך השלמת הכשרה מותאמת אישית שתכניס אותך לענף. כאמור, על הכשרות אצלנו לא משלמים שקל אם לא מתקבלים לעבודה…

                    '/>
              
                <QuestionAnswerNode
                 question='יש לי תואר אקדמי במדעי המחשב ולא מצאתי עבודה, מה אני יכול לעשות?'
                    answer='                        גם בוגרי תואר ראשון במדעי המחשב ממכללות נתקלים בימים אלה בקשיים גדולים בקבלה לעבודה הראשונה בענף ההיי-טק.
                    במיוחד כאשר הם מחפשים עבודה ראשונה בפיתוח תוכנה.
                    <br/>
                    <br/>
                    יש 2 אפשרויות מדוע סיימת תואר אקדמי במכללה ולא מצאת עבודה:
                    <ol>
                        <li>למרות רקע תאורטי רחב, חסר לך ידע פרקטי במקצוע המבוקש בענף ההי-טק - במיוחד כשדברים אמורים בפיתוח תוכנה.</li>
                        <li>אתה לא מתאים מבחינת הפרופיל האישיותי למקצוע המבוקש ו/או ״לא יודע איך לעבור ראיון״.</li>
                    </ol>     
                    <br />
                    הגש אלינו מועמדות באמצעות הטופס, נתחיל בתהליך האבחון, היעוץ והמיון המקצועי שלנו ללא כל עלות וללא כל מחויבות.
                    בסיום התהליך נוכל לתת לך תשובה מדויקת מה הסיבה שלא התקבלת לעבודה.
                    <br/>
                    במידה ויתברר שאתה מתאים למקצוע אליו ברצונך להתקבל,  נתאים לך השלמת הכשרה מותאמת אישית שתכניס אותך לענף. כאמור, על הכשרות אצלנו לא משלמים שקל אם לא מתקבלים לעבודה…
'/>
                
                <QuestionAnswerNode
                 question='סיימתי קורס בבית ספר אחר ולא מצאתי עבודה, אני שוקל ללמוד תואר במדעי המחשב, זה יעזור לי?'
                    answer='יכול להיות שכן ויכול להיות שלא.
                    אם תסיים תואר במדעי המחשב באוניברסיטה כמו הטכניון, אוניברסיטת תל אביב ודומיה בממוצע 85+ זה בפירוש יעזור.
                    לעומת זאת תואר במדעי המחשב מחלק מהמכללות יעזור הרבה פחות וכשמדובר על קבלה לעבודה בפיתוח תוכנה במקרים רבים לא ישנה את המצב.<br/> 
                    פנה אלינו להליך האבחון, היעוץ והמיון המקצועי שלנו ללא כל עלות וללא כל מחויבות.'/>
               
                <QuestionAnswerNode
                 question='אני מעונין להיות פרי לנסר, האם ניתן עדיין ללמוד אצלכם?'
                    answer='                    מי שאיננו מעונין להתקבל לעבודה כשכיר אלא להיות פרילנסר עדיין נדרש לעבור את תהליכי המיון כדי לוודא את 
                    התאמתו לקורס ולמקצוע. במידה ויעבור את תהליכי המיון יוכל להתקבל ללימודים לאחר שיסדיר את התשלום על הקורס המבוקש.
'/>
             
                <QuestionAnswerNode
                 question='כבר יש לי מקום עבודה, האם ניתן עדיין ללמוד אצלכם?'
                    answer='מי שכבר יש לו מקום עבודה ואיננו מעונין להחליף את מקום העבודה שלו, עדיין נדרש לעבור את תהליכי המיון כדי לוודא את 
                    התאמתו לקורס ולמקצוע. במידה ויעבור את תהליכי המיון יוכל להתקבל ללימודים לאחר שיסדיר את התשלום על הקורס המבוקש.
'/>
                
                <QuestionAnswerNode
                                question='איך מגישים מועמדות?'
                                answer={`באמצעות <a class='answer-link link-inline' href='#contact-hyper-footer'>טופס המועמדות באתר</a>  .`}/>
                   
                    </div>
                    </div>
                    </div>
          </div>
            <div id='public-question-section' className='public-quations'>
                <div id='public-question' className='section-container'>
                    <h2>שירות לציבור</h2>
                    <div id='how-much-salary' className='section-inner-container'>
                        <div className="quations-answers-container">
                <QuestionAnswerNode
                question='לא התקבלתי אצלכם ללימודים בתחום שעניין אותי, מה לעשות?'
                   answer='כפי שהוסבר באתר אנחנו ״מכללת בוטיק״, ולאור מודל ה״לא עבדת לא שילמת״ שלנו חייבים להיות סלקטיביים מאוד.
                   בהתאם אנחנו יכולים לטפל רק בכמות מצומצמת של מועמדים איכותיים.
                   מועמדים שלא מתקבלים ללימודים בתחום שעניין אותם, מקבלים מאיתנו תמיד יעוץ שמבהיר להם אם יש תחומים אחרים בהיי-טק שעשויים להתאים להם.
                   <br/>
                   מעבר לכך, אתם מכירים את עצמכם הכי טוב. וגם אנחנו יכולים לטעות. אם לא התקבלתם אצלנו, אבל אתם משוכנעים ביכולות שלכם אל תתיאשו וחפשו נתיב לימודי במקום אחר.
'/>
                
                <QuestionAnswerNode
                question='למרות שלא התקבלתי אצלכם, אני משוכנע ביכולות שלי ורוצה לחפש קורס במקום לימודי אחר. 
                    למה צריך לשים לב וממה צריך להזהר?'
                   answer='צריך להזהר מפנטזיות ולהצמד לעובדות בבחינת קורסים ומקומות לימודיים. אדם שבונה לעצמו פנטזיה אופטימית גם נוטה להאמין לתרגילים שיווקיים מופרכים.
                   ֿלא צריך לפתח פנטזיה לגבי שכר התחלתי גבוה, ולהזהר מטענות אנשי מכירות לגבי ״התחייבות לעבודה״, ״מלגת לימודים״ ו״אחוזי השמה״. '/>
                
                <QuestionAnswerNode
                                
                                question='כמה מרוויחים בענף ההיי-טק?'
                                answer='אין ספק שעבודה בענף ההיי-טק היא עבודת השכיר הרווחית והמשתלמת ביותר.
                   יש גם אין ספור טבלאות שכר באינטרנט שמפיצות חברות השמה שמראות על כך: <a class="answer-link"  target="_blank" href="https://www.jobinfo.co.il/%D7%98%D7%91%D7%9C%D7%90%D7%95%D7%AA-%D7%A9%D7%9B%D7%A8.aspx">טבלת שכר jobinfo</a> <a class="answer-link"  target="_blank" href="https://www.nisha.co.il/%D7%98%D7%91%D7%9C%D7%90%D7%95%D7%AA-%D7%A9%D7%9B%D7%A8">טבלת שכר נישה</a> <a  target="_blank" class="answer-link" href="https://www.ethosia.co.il/salary_report">טבלת שכר אתוסיה</a>
                   רוב בתי הספר מפנים לטבלאות השכר הללו ויוצרות את הרושם שהזהב נמצא ברחובות ופשוט צריך רק לאסוף אותו…
                   <br/>
                   <br/>
                   חשוב להבין שטבלאות השכר משקפות היטב את רמות השכר של עובדים בעלי 2 שנות ניסיון עבודה ומעלה.
                   לעומת זאת, הן בדרך כלל מפריזות בשכר של מועמדים חסרי ניסיון. חברות השמה בדרך כלל לא מטפלות בכלל בהשמה של בוגרים חסרי ניסיון אלא אם כן מדובר בבוגרי תואר ראשון בהצטיינות במדעי המחשב מהטכניון/אוניברסיטת תל אביב. משום כך השכר שמופיע בטבלאות לבוגרים חסרי ניסיון מופרז ולא משקף. חשוב להבין שקשה להכנס לעבודה ראשונה בענף כבוגר חסר ניסיון וציפיות השכר ההתחלתי צריכות להיות הרבה יותר צנועות. לא צריך להמתין יותר מדי, ״חגיגת השכר״ מתחילה אחרי שצוברים את 2 שנות הניסיון הקריטיות בעבודה…
'
                                isDefaultOpen={answersOpen===('#how-much-salary')}                            />
                        </div>
                        
                        <div className="quations-answers-container">
                <QuestionAnswerNode
                                id='work-promise-question'
                                question='בכל מיני בתי ספר הבטיחו לי ״התחייבות לעבודה״ ב״חוזה חתום״, זה אמיתי?'
                                answer='
                        בקיצור - ממש לא.
                        <br/>
                        <br/>
                   בפירוט:<br/>
                   אם ב״חוזה החתום״ היה כתוב שבכל מקרה כלל שכר הלימוד יוחזר אם לא תתקבל עבודה, היתה לזה משמעות.
                    <br/>
                    <br/>
                   בפועל מונח ה״התחיבות לעבודה״ משמש בתי ספר שונים כתרגיל שיווקי חסר כל משמעות. 
                   בדרך כלל ה״חוזה החתום״ מסייג את ההתחיבות בצורך לעמוד במגוון תנאים שאו שאינם אובייקטיביים, או שהינם בלתי אפשריים.
                    <br/>
                    <br/>
                   בנוסף בדרך כלל ״החוזה החתום״ מאפשר לבתי הספר לא לקיים את ההתחייבות לספק עבודה ו״לפצות״ את התלמיד בפיצוי חסר משמעות. חלק מבתי הספר מציעים החזר כספי שמהווה חלק זעום ממחיר הקורס שנגבה, חלק מבתי הספר מציעים ״לחזור על הקורס״ ללא תשלום נוסף, וחלק מבתי הספר לא מתביישים להציע ״פיצוי״ בדמות תשלום נוסף של התלמיד תחת ״הנחה״ על קורס נוסף…
                    <br/>
                    <br/>
                   האם צריך לפסול בתי ספר שמציעים התחייבויות שווא כאלה ולבחור באלו שלא? לאו דווקא. צריך פשוט להשוות בין בתי הספר תוך התעלמות מההתחייבות המדומה.
'
                                isDefaultOpen={answersOpen==='#work-promise-question'}
                
                            />
                
                <QuestionAnswerNode
                question='בכל מיני בתי ספר הבטיחו לי מלגה, זה אמיתי?'
                                answer='בקיצור - לא.
                   <br/>
                   <br/>
                   בפירוט:
                   <br/>
                   מלגה זו הנחה מיוחדת שמקבלים יחידי סגולה כאשר כל שאר התלמידים משלמים יותר.
                   <br/>
                   כשכל התלמידים מקבלים ״מלגה״ זה פשוט מצב בו מעלים את מחיר הקורס מראש ואז מורידים אותו למחיר האמיתי באמצעות ״מלגה״.
'/>
                
                <QuestionAnswerNode
                question='בית ספר/מכללה אקדמית מסויימת טוען שיש לו אחוז השמה מסויים של הבוגרים בענף. זה אמיתי?'
                                answer='אף אחד לא יודע. כנראה שלא.
                   <br/>
                   <br/>
                   אין שום פיקוח על הענף ואין שום דרך אמיתית לבדוק את הנתונים. בפועל כל איש מכירות זורק למועמדים את המספרים שלדעתו ישכנעו אותם להרשם.
'/>
                   
                
             
                        </div>
                        </div>
</div>
            </div>
            
           
        
            </div>
    )
}