import React,{useState,useEffect} from 'react'
import '../styles/PrivacyModal.css'
export default (props) => {
    return (
        <div {...props} className={`privacy-modal`}>
            <div className='modal-privacy-content'>
              
                <i onClick={(e)=>props.closeCallBack(true)} class="fas fa-times exit-button"></i>
                
                <h3>מדיניות הגנת הפרטיות</h3>

                השימוש באתר אינטרנט זה כפוף לתנאי השימוש ולמדיניות הפרטיות כמפורט להלן.<br/>
                בגלישתך באתר הנך מקבל ומסכים לתנאים ולמדיניות הפרטיות כמפורט להלן. <br/>
        <br/>
האתר מופעל על ידי הייפראקטיב היי-טק בע"מ (להלן: "הייפראקטיב") לטובת איסוף פרטים ויצירת קשר עם מתעניינים בלימודי מקצועות הי-טק.
<br/>
     <br/>
    <h6>המידע שנאסף באמצעות האתר:</h6><br/>
    המידע היחיד שנאסף באמצעות האתר הינו פרטי קשר בסיסיים - שם מלא, מספר טלפון וכתובת דואר אלקטרוני.<br /><br />
                
    <h6>השימוש שנעשה במידע:</h6><br/>
    המידע משמש לצורך יעוץ, מכירות, שיווק, גיוס לקוחות, גבייה, וכן למתן שירות ללקוחות, דיוור ישיר וקשר שוטף עם הלקוח.<br/><br/>
    המידע לא יועבר על ידינו לצדדים שלישיים, אלא לדרישת רשות מוסמכת או לאחר קבלת הסכמתך לכך מראש.<br/><br/>
    <h6>Cookies (עוגיות):</h6><br/>
        
    קובץ ״עוגיה״ (cookie)
    הוא קובץ המכיל חלקי מידע שהאתר יוצר במחשבך בעת הגלישה באתר. הקובץ מאפשר שמירת מידע לגבי העדפותיך בכל הקשור בשימוש באתר.
    <br/>
אתר הייפראקטיב משתמש בקובץ עוגיה. רוב הדפדפנים יודעים לזהות מתי אתר יוצר קובץ עוגיה, ולאפשר לך לסרב או לקבל אותו. אם אינך בטוח אם לדפדפן שלך יש יכולת זו, עליך לבדוק עם יצרן התוכנה או ספק שירותי האינטרנט שלך.בכל מקרה אנחנו לא משתמשים בקובץ עוגיה כדי לעקוב אחר הרגלי הגלישה שלך מחוץ לאתר שלנו.

                <br />
                <br/>
    <h6>שמירה על פרטיותך:</h6><br/>
    הפרטיות שלך חשובה לנו. בהתאם, אנחנו פועלים בהתאם להוראות חוק הגנת הפרטיות, 1981. בין היתר אנחנו אוספים מידע אודותיך רק כאשר אתה מספק לנו אותו ביודעין ובמפורש.
                <br/>
    אנחנו מנהלים את האתר בסטנדרט האבטחה הגבוה כדי לשמור על פרטיך ומגינים על המידע שאתה מוסר לנו בצורה המיטבית.
            <br/>
    מידע המאפשר זיהוי אישי שלך, נשמר בסודיות מוחלטת ופרטי זיהוי שאתה מספק לנו לא זמינים לגורמים שלישיים ללא אישורך מראש.
    <br />
    <br/>
    בנוסף אתה שולט בפרטי הזיהוי שלך, בהתאם באפשרותך לפנות אלינו בכל עת באמצעות מייל ל-info‪@‬hyperactive‪.‬co‪.‬il ולבקש כל אחת מהפעולות הבאות:
    <br />
                <br/>
    1. לבדוק איזה מידע אישי יש לנו.<br />
    2. לשנות את המידע שמסרת לנו.<br/>
    3. לבקש מחיקת המידע האישי.<br />
                <br/>
                <h6>אחריות מוגבלת</h6>
                <br/>
כל התכנים באתר זמינים as is והייפראקטיב איננה נותנת כל מצג ו/או התחיבות הנובעת מתכני האתר. הייפראקטיב לא אחראית ולא תשא בכל אחריות שהיא בקשר לשימוש באתר, בתכנים או להסתמכות עליהם. 
                <br />
                <br/>
הייפראקטיב שומרת על זכותה לעשות כל שינוי באתר, במידע ובשירותים המסופקים על ידה, בכל עת וללא הודעה מראש. 
                <br/>
יתכן שהאתר והתכנים בו לא יהיו עדכניים והייפראקטיב לא מתחייבת כלל לעדכנם ובהתאם אין להסתמך עליהם ככאלה.
                <br/>

<br/>
    <h6>יצירת קשר:</h6><br/>
    ניתן ליצור עמנו קשר בכל עת בכתובת הדואר האלקטרונית הבאה  info‪@‬hyperactive‪.‬co‪.‬il<br/>
<br/>
<br/><br/><br/><br/><br/>
            </div>
        </div>
    )
}