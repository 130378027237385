import React, { useEffect,useState } from 'react'
import { handleForm ,sendEmail} from '../utils/form'
import '../styles/Modal.css'
export default (props) => {
    const showModalHandler = () => {
        localStorage.setItem(`modal`, Date.now())
                setIsModalOpen(true)
    }
    useEffect(() => {
     const modalInterval=setInterval(() => {
            let lastAppear;
            if(localStorage.getItem('modal'))
                lastAppear = localStorage.getItem('modal')
                
            else
                showModalHandler()
            const minutesPass = Math.floor((Math.abs(Date.now() - lastAppear) / 1000) / 60) % 60;

            if (minutesPass > 15) {
                showModalHandler();
            }
        }, 5000);
        window.addEventListener('click',()=>localStorage.setItem(`modal`, Date.now()))
        return ()=>clearInterval(modalInterval)
        
    }, [])
    
    const [isModalOpen, setIsModalOpen] = useState(false)
    const setUnvalidPlaceholders = () => {
        const nameInput = document.getElementById('modal-name-input');
        const phoneNumber = document.getElementById('modal-phone-input');
        const email = document.getElementById('modal-email-input');
        if (nameInput.classList.contains('unvalid'))
            nameInput.placeholder = '*נא להזין שם תקין'
        if (phoneNumber.classList.contains('unvalid'))
            phoneNumber.placeholder = '*נא להזין מספר טלפון'
        if (email.classList.contains('unvalid'))
            email.placeholder='*נא להזין כתובת מייל'
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        const isSendEmail=await props.handleSubmit(e)
        if (isSendEmail.validate)
            setIsModalOpen(false);
        else
            setUnvalidPlaceholders();
      
    }
    return (
        <div className={`modal-background ${isModalOpen?'show':''}`} >
        <div className="modal">
            <div onClick={(e)=>setIsModalOpen(false)} className="modal-exit-button">
                <div id="exit-line1" className="exit-line"></div>
                <div  id="exit-line2" className="exit-line"></div>
            </div>
            <div className="modal-content">
                <div className="modal-text">
                    <div className="modal-title">מעוניינים לקבל ייעוץ חינם לגבי לימודי הייטק? השאירו פרטים! </div>
                  
                </div>
                    <form onSubmit={onSubmit} className="modal-form">
                     
                        <div className='inputs-group'>
                        <div></div>
                            <div className="input-wrapper">
                                <input className='modal-input' name='name' id='modal-name-input' placeholder="שם מלא" type="text"></input>
                            </div>
                            <div className="input-wrapper">
                                <input className='modal-input' name='phone' id='modal-phone-input'  placeholder="מספר טלפון" type="text"></input>
                            </div>
                            <div className='input-wrapper'>
                                <input className='modal-input' name='email' id='modal-email-input' placeholder="אי-מייל" type="text"></input>
                                </div>
                    </div>
                    <div className="agree-checkbox">
                        <label className="agree-explain">אני מאשר קבלת עדכונים ומבצעים מהייפראקטיב.</label>
                        <input defaultChecked={true} className="checkbox" type="checkbox" /> 
                    </div>  
                    <button className="modal-button"><i class="fas fa-chevron-left"></i><i class="fas fa-chevron-left"></i> דברו איתי </button>    
                </form>
            </div>
           
           
        </div>
    </div>
    )
}