import React,{useEffect} from 'react'
import LogoWidget from './DataPageLogo'
import ContactBox from '../FooterContact'
import ContactUs from '../ContactUs'
import '../../styles/pages/dataPages.css'
const title = ' קריירה באבטחת מידע';
export default () => {
        useEffect(() => {
                document.title=title
            },[])
        return (
                <div className="data-page-wrapper">
                <ContactUs className=' data-page-contact'/>

                        <div className="data-page" >
                    
                    <LogoWidget id='cyber-data-page-logo' imageSrc={require('../../images/courses-icons/cyber_course.png')} title={title}/>
    
                    <h1 className='main-page-title'>קריירה בסייבר ואבטחת מידע</h1>
    <p className='data-section start-section '>
            תחום הסייבר ואבטחת המידע הינו התחום הצומח בקצב המהיר ביותר בתעשית ההי-טק בשנים האחרונות. החשיבות העצומה של רשת האינטרנט לחיים המודרניים הפכה את האינטרנט לכר פורה לפשעי סייבר. בהתאם קיים ביקוש גדול לאנשי סייבר במגוון תפקידים גדול הן בפן ההגנתי/מניעתי והן בפאן ההתקפי/אבחוני.
    </p>
                    <ContactBox className='mobile-only' />
                    <div className="data-page-content">
                <div className="positions-section data-section">
    <h2 className=' data-subtitle'>תפקידים בתחום</h2>
    <p>
                                    בין היתר קיים ביקוש לאנשי penetration testing, אנליסטים ועובדים במערכי soc, מתפעלי siem, יועצי אבטחת מידע, ארכיטקטי הגנת סייבר, מומחי reverse engineering ועוד.
    </p> 
                    </div>
                    <div className="prerequisites-section data-section">
    <h2 className='data-subtitle'>קהל יעד ודרישות קדם</h2>
    <p>‬
        הילת ההאקר והדימוי המרגש של תחום הסייבר מושך מועמדים רבים.בנוסף לרבים נראה שכל אחד יכול לעסוק בתחום.
    </p>
    <p>    
            חשוב להבהיר שמהות העבודה בתחום שונה מאוד מדימוי זה.
            מדובר בתחום רציני שביום יום שלו דורש עבודה יסודית ומתודולוגית, עם רמת ריגוש יומיומית נמוכה יחסית.סייבר התקפי דורש שלבי תכנון ואיסוף מודיעין ארוכים, כאשר רק לאחר מאמץ רב מגיע בדרך כלל ההשג, ואילו סייבר הגנתי דורש מעקב אחר שטף נתונים לאורך זמן ודורש עירנות וסבלנות גבוה.
    </p>
    <p>    
            לרוב תפקידי הסייבר לא נדרשת יכולת אלגוריתמית גבוה כפי שנדרש בתפקידי פיתוח התוכנה, אך התחום דורש לימוד פרקים טכנולוגיים משמעותיים מעולמות התשתיות, רשתות ופיתוח התוכנה כאחד.
            בנוסף קיים צורך לעקוב ולהתעדכן כל הזמן בשינויים המהירים בתחום, ב - vulnerabilities החדשות שמתגלות מדי יום ולהערך בהתאם.
    </p>
    <p>    
            בהתאם אנחנו בדרך כלל מצפים ממועמדים להיות בעלי חוש טכני מפותח, שליטה טובה באנגלית, ויכולת לימוד עצמי טובה.
            עקב הביקוש הגבוה לעובדים במרכזי soc אנחנו לא מציבים דרישה מוקדמת לתואר אקדמי ממועמדים להכשרה לתחום הסייבר.
    <br />
    מאחר שאנחנו עובדים בשיטת ״לא עבדת לא שילמת״ כל מועמד עובר תהליכי אבחון ומיון יסודיים בטרם קבלה למסלולי הכשרה בסייבר ואבטחת מידע.        
    </p> 
   </div>
                    <ContactBox className='mobile-only'/>
                    <div className="training-section data-section">
    <h2 className='data-subtitle'>מסגרת ההכשרות</h2>
    <p>
            מסלולי ההכשרה לסייבר ואבטחת מידע מועברים במסגרת לימודי בוקר, לימודי ערב ולימודים היברידיים, ונמשכים בין 7 - 14 חודשים.
            מסלולי ההכשרה כוללים סה״כ כ - 540 שעות לימוד ותרגול אקדמאיות.
            הלימודים מתקיימים בקצב מהיר וכוללים כמות גדולה מאוד של שיעורי בית, מבחנים, פרוייקטים ועבודות להגשה.
    <br />
    מלבד תעודת הסמכה מטעמנו, תלמידים המעוניינים בכך יכולים לגשת למבחני הסמכה של חברת מיקרוסופט בתחום.        
    </p>  
    
       </div>
       <div className="prerequisites-section data-section">             
    <h2 className='data-subtitle'>נושאים עיקריים בהכשרות</h2>
    <ul className='subjects-list'>
    <li>מבוא לרשתות תקשורת</li>
    <li>מבוא למערכות הפעלה</li>
    <li>מבוא לתכנות</li>
    <li>מבוא ל-web</li>
    <li>שרתי windows</li>
    <li>שרתי linux</li>
    <li>טכנולוגיות cisco</li>
    <li>מבוא לאבטחת מידע וסייבר</li>
    <li>מבוא לסייבר התקפי</li>
    <li className='ltr-list-item'>Penetration testing</li>
    <li>מבוא לסייבר הגנתי</li>
    <li className='ltr-list-item'>Siem, Soc</li>
    <li>תכנות בשפת python</li>
    <li className='ltr-list-item'>Web & mobile security</li>
    <li className='ltr-list-item'>Certification tests preparation</li>
                            </ul>    
                                    </div>
                                    </div>
                        </div>
                        
        </div >)
}
