import React from 'react'
import '../styles/NavigationBar.css'
import {Link}from 'react-router-dom'
import { useEffect,useState } from 'react'
export default () => {
  const mobilePrespectiveKeeper=()=>
    {const navLinkesToggale = document.getElementsByClassName("menu-button")[0];
    setInterval(() => {
            if(navLinkesToggale)
            {
                const isMobileDisplay = (window.getComputedStyle(navLinkesToggale).getPropertyValue('display') !== "none")
            if(!isMobileDisplay)
            {
                const mobileOpenElements = document.getElementsByClassName("mobile-open")
                if (mobileOpenElements.length > 0)
                    for (let index = 0; index < mobileOpenElements.length;index++)
                        mobileOpenElements[index].classList.remove("mobile-open")
            
                        navLinkesToggale.classList.remove("open")
                }
               
            }
        },100)}
    const [isNavOpen, setIsNavOpen] = useState(false);
    useEffect(() => {
        mobilePrespectiveKeeper();
        const navLinkList = document.getElementsByClassName("nav-link-inner-drop-down-container")
        const dropDownLinkList=document.getElementsByClassName("drop-down-inner-menu")
        for (let index = 0; index < navLinkList.length;index++)
        {
            let navLink = navLinkList[index];
            let dropDownLink = dropDownLinkList[index];
            if (index >= dropDownLinkList.length)
                return;
            navLink.addEventListener("click", () => {
            if (dropDownLink.classList.contains("open"))
                dropDownLink.classList.remove("open")
            else
                dropDownLink.classList.add("open")
            })
        }
        
       
    }, [])
    const handleOpenInnerMenu = () => {
        setIsNavOpen(false);
                                const innerMenu=document.getElementsByClassName('drop-down-inner-menu')[0]
                                innerMenu.classList.add('hide');
                                setTimeout(()=>{innerMenu.classList.remove('hide');},10)
                                
    }
    return (
        <div id='navigation-bar' className={`navigation-bar ${isNavOpen?"mobile-open":""}`}>
            <div onClick={() => setIsNavOpen(!isNavOpen)} className={`menu-button ${isNavOpen?"open ":""}`}>
            <div id="menu-line-top" className="menu-line"></div>
            <div id="menu-line-middle" className="menu-line"></div>
            <div id="menu-line-bottom" className="menu-line"></div>
        </div>
        <div className="navigation-container">
            
            
            <div className="nav-links-container">     
                <div className={`drop-down-container ${isNavOpen?"mobile-open":""}`}>
               
                    <li className="nav-link mobile-only"><Link onClick={()=>setIsNavOpen(false)} id="home-btn"  to="/">בית</Link></li>
                    <div className="drop-down">
                    </div>
                    
                    </div>     
                    <div className={`drop-down-container ${isNavOpen?"mobile-open":""}`}>
                   
                    <li className="nav-link arrow-after nav-link-inner-drop-down-container splitter ">קריירות<i class="fas fa-sort-down"></i></li>
                    <div className="drop-down drop-down-inner-menu">
                            <Link onClick={() => {
                                handleOpenInnerMenu()
                            }} id="navigationbar-cyber-page-link" to="/cyber-page">סייבר ואבטחת מידע |  ethical hacking</Link>
                            <Link onClick={()=>handleOpenInnerMenu()} id="navigationbar-system-page-link" to='/system-admin-page/#navigation-bar'> ניהול  רשתות ומחשוב | system administration</Link>
                            <Link onClick={()=>handleOpenInnerMenu()} id="navigationbar-marketing-page-link" to='/marketing-page'> שיווק דיגיטלי | seo/social/sales&marketing</Link>
                            <Link onClick={()=>handleOpenInnerMenu()} id="navigationbar-qa-page-link" to='/qa-page'>בדיקת תוכנה | qa & automation</Link>
                            <Link  onClick={()=>handleOpenInnerMenu()} id="navigationbar-ui-page-link" to='/ui-ux-page'>עיצוב גרפי | ui/ux</Link>
                            <Link  onClick={()=>handleOpenInnerMenu()} id="navigationbar-fullstack-page-link" to='/fullstack-page'>פיתוח תוכנה | full stack  </Link>
                            <Link  onClick={()=>handleOpenInnerMenu()} id="navigationbar-embedded-page-link" to='/embedded-page'>פיתוח מערכות משובצות מחשב  | embedded</Link>
                            <Link  onClick={()=>handleOpenInnerMenu()} id="navigationbar-mobile-page-link" to='/mobile-page'>פיתוח אפליקציות מובייל  | ios/android</Link>
                    </div>
                    </div>
                    <div className={`drop-down-container ${isNavOpen?"mobile-open":""}`}>
                   
                    <a href='/#pros-section' className="nav-link  splitter ">למה ללמוד אצלנו?</a>
                   
                    </div>
                    <div className={`drop-down-container ${isNavOpen?"mobile-open":""}`}>
                   
                    <a href='/#graduate-section' className="nav-link splitter ">כאן תמצאו את הבוגרים שלנו</a>
                    
                    </div>
                    
           
                <div className={`drop-down-container ${isNavOpen?"mobile-open":""}`}>
                   
                <a href='/#about-us-section' className="nav-link splitter ">קצת עלינו</a>
                
                </div>   
                    <div className={`drop-down-container ${isNavOpen?"mobile-open":""}`}>
                   
                <a href='/#how-it-work-section' className="nav-link  splitter">איך זה עובד</a>
                   
                </div>
              
                <div className={`drop-down-container ${isNavOpen?"mobile-open":""}`}>
                   
                <a href='/#quations-answers-section' className="nav-link splitter ">שאלות שכנראה תשאלו</a>
                   
                </div>
                <div className={`drop-down-container ${isNavOpen?"mobile-open":""}`}>
                <a href='/#public-question-section' className="nav-link  non-splitter">שירות לציבור</a>
                  
                    </div>
                    </div>   
                    
            </div>
            <a href='/'>
            <div className="logo-container">
            
            <img className="logo" src={require('../images/hyperactive_logo.png')}>
            
            </img>
            <div className='nav-subtitle'>בית הספר להיי-טק הטוב בישראל</div>
            </div>
            </a>
            </div>
          
   
    )
}