import React,{useEffect} from 'react'
import LogoWidget from './DataPageLogo'
import ContactBox from '../FooterContact'
import ContactUs from '../ContactUs'

const title = 'קריירה בפיתוח אפליקציות מובייל';
export default () => {
        useEffect(() => {
                document.title=title
            },[])
        return (
                <div className="data-page-wrapper">
                <ContactUs className=' data-page-contact'/>

                        <div className="data-page" >
                    
                    <LogoWidget id='mobile-data-page-logo' imageSrc={require('../../images/courses-icons/mobile_course.png')} title={title}/>
    
                    <h1 className='main-page-title'>קריירה בפיתוח אפליקציות מובייל</h1>
                                <div className='data-section start-section '>
                                <p className='section-emphasis'>
                                קורסי הפיתוח שלנו הינם הטובים בישראל. הקורסים מיועדים עבור מועמדים שמעונינים בהכשרה יסודית וכניסה מהירה לעבודה בפיתוח תוכנה בענף ההיי-טק.
                                                                     <br />
                                <br />
                                עם זאת, אם אתה מוכן לעבור הכשרה ארוכה יותר, ומוכן להתחייב מראש לעבוד מספר שנים כמפתח תוכנה,
                                "<a href='https://talpiot-hitech.com/' target='_blank'  className='underline-emphasis inline-item'>תלפיות היי-טק</a>"
                                עדיפה עבורך. תלפיות היי-טק מציעה תוכנית הכשרה ארוכה ומעמיקה מאוד של אנשי פיתוח, במגוון גדול של טכנולוגיות ובמודל בו התלמידים לא משלמים כלל שכר לימוד ומתחייבים לעבוד עבור החברה מספר שנים.
                        </p>
                                        <p>
                                                מכל מקצועות ההיי-טק אין ספק שהמקצוע המאתגר ומתגמל ביותר הן כספית והן מבחינת מסלול קריירה הינו מקצוע מפתח התוכנה.
                                                         <br />
                                                         ללא קשר לכינוי שניתן למשרות על ידי חברות שונות - ״תוכניתן״, ״איש פיתוח״, ״מהנדס תוכנה״, ״ארכיטקט תוכנה״ וכו׳ בבסיס מפתח התוכנה עוסק בכתיבת תוכניות מחשב שנועדו לממש אלגוריתמים לפתרון מגוון בעיות בחיים המעשיים.
                                        </p>
                                        <p>
                                                פיתוח אפליקציות מובייל הינו תת ענף בתחום פיתוח התוכנה, העוסק בפיתוח תוכנה המיועדת לרוץ על גבי טלפונים חכמים.
                                        <br />
                                        תחום הסמארטפונים פועל כדואופול - כאשר מערכת ההפעלה אנדרואיד שולטת בקרוב ל-80% מהשוק בעוד אפל ומערכת ההפעלה שלה ios משמשים כ-20% מהשוק.        
                                           <br />
                                           למרות נקודות דמיון רבות הקיימות באופן השימוש באייפון ובמכשירי אנדרואיד, קיים הבדל גדול בין אופן פיתוח האפליקציות לכל מערכת - קיים צורך ללמוד שפות תכנות שונות, ספריות קוד שונות ואפילו ליישם תפיסות ממשק משתמש ועיצוב שונות.     
                                        </p>
                                        <p>
                                        חשוב לדעת שניתן לפתח תוכנה לאנדרואיד באמצעות כל מחשב אבל ניתן לפתח תוכנה לאייפון רק באמצעות מחשבי מק של אפל.                                       
                                        </p>
                                        
                    <ContactBox className='mobile-only' />
                    <div className="data-page-content">
                <div className="positions-section data-section">
    <h2 className=' data-subtitle'>תפקידים בתחום</h2>
    <p>
    התפקיד הראשון בתחום הינו מפתח תוכנה. מפתחי תוכנה מתחילים (junior) בתחום עובדים בדרך כלל בצוות של 4-5 מפתחים תחת ראש צוות. ברוב החברות קיימים מסלולי קידום מקצועיים וניהוליים כאחד. לאחר מספר שנים בתור מפתח senior מקבלים לרוב אחריות מוגברת ועצמאות גבוה יותר בעבודה  או הופכים לראש צוות. בהמשך ניתן להתקדם לניהול מספר צוותים, ראש מחלקה ולבסוף מנהל פיתוח. אנשים בעלי נטיות עסקיות נודדים לאחר מספר שנות פיתוח למגוון תפקידים עסקיים בחברות הגדולות, הדורשים גם רקע והבנה טכנולוגית מעמיקה.                    </p>
                                                        </div>
                                                        <div className="prerequisites-section data-section">
    <h2 className='data-subtitle'>קהל יעד ודרישות קדם</h2>
    <p>‬
      קיים ביקוש למפתחי אפליקציות בעיקר בחברות סטארט אפ ובחברות פרוייקטים שמתמחות בפיתוח פתרונות מובייל.
    <br />
    חלק ממפתחי המובייל בוחר לעבוד כפרי לנסר עם היתרונות של סגנון חיים של digital nomad.                                                                          
     </p>
    <p>    
                                                                כלל תפקידי פיתוח התוכנה דורשים יכולת לוגית גבוה, חשיבה מתמטית טובה מאוד ואנגלית מצויינת.
     <br />
     כדי להיות פרי לנסר צריך מעבר לכך גם כשרון בסיסי למכירות ועסקים.
                                                           
     </p>
            
            
    <p>    
                                                                כמחצית התלמידים במסלולי ה- mobile אצלנו הינם בעלי תואר ראשון בהצטיינות וציון פסיכומטרי 680 ומעלה.
                                                                    <br />
                                                                <br/>
                                                                    אם אין לך את הנתונים האלה אבל אתה מאמין ביכולות שלך ובעל רצון עז להיות מפתח תוכנה, הגש מועמדות בכל מקרה.
                                                                    <br />
                                                                    אנחנו עובדים, כידוע, בשיטת ״לא עבדת לא שילמת״ וכל מועמד עובר תהליכי אבחון ומיון יסודיים בטרם קבלה למסלולי ההכשרה. אם תעבור אותם בהצלחה, הנתונים הסטטיסטיים הראשונים שלך לא ישנו.            
                                                                    </p>
                                                                    </div>
                    <ContactBox className='mobile-only'/>
                    <div className="training-section data-section">
    <h2 className='data-subtitle'>מסגרת ההכשרות</h2>
    <p>
                                                                                מסלולי ההכשרה בפיתוח תוכנה לסביבת mobile מועברים במסגרת לימודי בוקר אינטנסיביים, לימודי ערב ולימודים היברידיים, ונמשכים בין 4-12 חודשים.
                                                               <br />
                                                               מסלולי ההכשרה כוללים כ- 500 שעות לימוד ותרגול אקדמאיות.
                                                                                                                               <br />
                                                                                                                               הלימודים מתקיימים בקצב מהיר וכוללים כמות גדולה מאוד של שיעורי בית, מבחנים, פרוייקטים ועבודות להגשה.
                                                                                                                                                                                       </p>  
    
       </div>
       <div className="prerequisites-section data-section">             
    <h2 className='data-subtitle'>נושאים עיקריים בהכשרות</h2>
    <ul className='subjects-list'>
    <li>מבוא לפיתוח תוכנה</li>
    <li>מבני נתונים ואלגוריתמים</li>
    <li>מסדי נתונים ושפת SQL  </li>
    <li className='ltr-list-item'>Java</li>
    <li className='ltr-list-item'>Kotlin</li>
    <li className='ltr-list-item'>Objective c</li>
    <li className='ltr-list-item'>Swift</li>
    <li className='ltr-list-item'>Android api </li>
    <li className='ltr-list-item'>iOS api</li>
    <li >תכנות מבני</li>
    <li >תכנות מוכוון עצמים </li>
    <li>אופטימזציה ו-Profiling‬</li>
                            </ul>    
                                    </div>
                                    </div>
                        </div>
                        
                        </div ></div>
        )
}
