import React,{useEffect} from 'react'
import LogoWidget from './DataPageLogo'
import ContactBox from '../FooterContact'
import ContactUs from '../ContactUs'

const title = 'קריירה בעיצוב גרפי';
export default () => {
        useEffect(() => {
                document.title=title
            },[])
        return (
                <div className="data-page-wrapper">
                <ContactUs className=' data-page-contact'/>

                        <div className="data-page" >
                    
                    <LogoWidget id='ui-data-page-logo' imageSrc={require('../../images/courses-icons/ui_course.png')} title={title}/>
    
                    <h1 className='main-page-title'>קריירה בעיצוב גרפי</h1>
                                <div className='data-section start-section '>
                                        <p>
                                        בעולם המודרני עיצוב גרפי, דיוק ממשק המשתמש ה-ui, ודיוק חווית המשתמש ה-ux חיוניים להצלחת מוצרים ושירותים.
                                      <br />
                                      תוכנה יעילה ופונקציונלית להפליא תכשל אם ממשק המשתמש לא יהיה מרשים ואינטואיטיבי לשימוש - המשתמשים פשוט יוותרו על השימוש בה ולא יתנו לה צ׳אנס נוסף. 
                                        </p>
                                        <p>
                                                חברת אפל היא חברת הטכנולוגיה הגדולה בעולם תודות לפרפקציוניזם חסר הפשרות של סטיב ג׳ובס בעיצוב וחווית המשתמש של המוצרים. לאחר מותו של סטיב ג׳ובס הפך ג׳ון אייב לגורם החשוב ביותר בהובלת מוצרי החברה - וזאת מתפקיד המעצב הראשי…
                                        </p>
                                        <p>
                                                עיצוב גרפי התקיים כמקצוע משגשג שנים רבות בטרם מהפכת הדיגיטל של האינטרנט. אולם בעבר עיצוב גרפי התמקד בעיקר ביצירת חומרים פרסומיים לדפוס.
                                                עם פריצת האינטרנט והגידול העצום ברשתות החברתיות נוצר ביקוש עצום לעיצוב מתוחכם יותר, עיצוב של תכנים למדיות דיגיטליות. כאשר לא קיים צורך בדפוס, התוכן מתחלף באופן שוטף ויחד איתו הצורך בכח אדם מיומן לעיצוב תכנים חדשים שוב ושוב. הדבר נכון לגבי אתרי אינטרנט, דפי מדיות חברתיות, ממשקי תוכנות מדף, ממשקי אפליקציות מובייל ועוד ועוד.

                                        </p>
                                        <p>
                                                בעבר היה מספיק שמוצר יראה יפה, ש״מסכי התוכנה״ יראו יפים כדי שלקוח ישתמש בהם. כיום, כאשר קיים שפע כמעט אין סופי של תוכנות, אתרים ואפליקציות מתחרות, משתמשים מצפים לא רק שהממשק יראה יפה אלא שגם יהיה אינטואיטיבי וזריז. אף אחד לא רוצה לקרוא ״מדריכים למשתמש״ וכאשר מוצר מתחרה נמצא במרחק הקלקה בודדת, אין נאמנות למוצרים שלא מדייקים את חווית המשתמש באופן מושלם.
                                        </p>
                                        <p>
                                        בעולם הותיק קיים ביקוש למעצבים גרפיים בבתי דפוס, מערכות עיתונים, מגזינים ורשתות טלויזיה.
בעולם המודרני מעצבים גרפיים, ובמיוחד אלה השולטים גם ברזי חווית המשתמש - ux, מבוקשים במיוחד במשרדי פרסום, יחסי ציבור וסטודיו לעיצוב.
מקומות העבודה המבוקשים ביותר והיכן שלרוב נדרשת הרמה הגבוה ביותר הינן כמובן חברות היי-טק ובמיוחד חברות סטארט אפ, היכן שהעבודה המתגמלת והיצירתית ביותר.
                                        </p> 
                                </div>
                    <ContactBox className='mobile-only' />
                    <div className="data-page-content">
                <div className="positions-section data-section">
    <h2 className=' data-subtitle'>תפקידים בתחום</h2>
    <p>
    יש מגוון תפקידים בתחום שמשקפים התמקדות בחלק מתהליך התכנון והעיצוב או בחלק מהשלבים הביצועיים - מעצב/ת גרפי, מומחי ui, מעצב חווית משתמש ux, איש ui‪/‬ux, גרפיקאי, ביצועיסט, ארט דירקטור ועוד.    </p> 
                    </div>
                    <div className="prerequisites-section data-section">
    <h2 className='data-subtitle'>קהל יעד ודרישות קדם</h2>
    <p>‬
    תחום העיצוב הגרפי בעל תדמית מעניינת ויצירתית ומושך אליו כמות גדולה מאוד של תלמידים. וכשיש ביקוש ללימודים יש גם היצע גדול של קורסים מסוגים שונים ותארים אקדמאיים.     </p>
    <p>    
    כמעט כל השוק, כולל המוסדות המלמדים לתואר אקדמי במשך 3-4 שנים, מקבלים כמעט כל אחד ללימודים.<br/>
    חשוב להבין שללא כישרון עיצובי טבעי, חוש טעם ואסתטיקה ומיומנות טכנית גבוה, לא ניתן למצוא עבודה בתחום עם תואר או בלעדיו. 
    <br />
    כתוצאה מכך יש המוני בוגרי עיצוב גרפי מובטלים שהתיאשו מהתחום.                                                
    </p>
    <p>    
    בנוסף, בשטח, אין כמעט משמעות לתואר אקדמי בתחום. אין ספק שמעצב גרפי בעל שנת נסיון מהווה מועמד אטרקטיבי בהרבה לרוב החברות מאשר בוגר טרי של תואר בעיצוב גרפי.
    <br/>
    כדי להיות איש ux טוב נדרשת גם יכולת אנליטית טובה, מעבר לכישרון העיצובי. כדי לעבוד בחברות היי-טק נדרשת, כרגיל, אנגלית ברמה גבוה.
        </p>
    <p>    
                                                        מועמדים אידיאליים יפגינו גם מיומנות באיור ידני.
                                                        <br /><br/>
                                                        חלק גדול מהעוסקים בתחום העיצוב הגרפי בוחרים בו כדי לייצר הכנסה נוספת במשרה חלקית כפרי לנסרים. אנשים אלה ממשיכים לעבוד במקצוע הנוכחי שלהם ובמקביל  מנהלים עסק הנותן שירותים בתחום העיצוב הגרפי בהיקף משתנה. חלקם בוחרים בהמשך, לאחר גיבוש קהל לקוחות, לפרוש מהמשרה הנוכחית שלהם  או אפילו לאמץ סגנון חיים של digital nomad.
                                                        
    </p> 
                                                <p>
                                                אנחנו עובדים, כידוע, בשיטת ״לא עבדת לא שילמת״ וכל מועמד עובר תהליכי אבחון ומיון יסודיים בטרם קבלה למסלולי ההכשרה. לתלמידים המעונינים לעבוד בתחום כפרי לנסרים קיימת מסגרת ״לא עבדת לא שלמת״ יחודית עבורם.
                                                </p>
   </div>
                    <ContactBox className='mobile-only'/>
                    <div className="training-section data-section">
    <h2 className='data-subtitle'>מסגרת ההכשרות</h2>
    <p>
    מסלולי ההכשרה בעיצוב גרפי מועברים במסגרת לימודי בוקר, לימודי ערב ולימודים היברידיים, ונמשכים בין 6-14 חודשים.
    <br />
    מסלולי ההכשרה כוללים סה״כ כ-540 שעות לימוד ותרגול אקדמאיות.
                                                        <br />
                                                        הלימודים מתקיימים בקצב מהיר וכוללים כמות גדולה מאוד של שיעורי בית, מבחנים, פרוייקטים ועבודות להגשה.                                                
        </p>  
    
       </div>
       <div className="prerequisites-section data-section">             
    <h2 className='data-subtitle'>נושאים עיקריים בהכשרות</h2>
    <ul className='subjects-list'>
    <li>מבוא לעיצוב גרפי לענף ההיי-טק</li>
    <li>יסודות בטכנולוגיות web - html ‪&‬ css</li>
    <li>בניית אתרים באמצעות wordpress</li>
    <li className='ltr-list-item'>Photoshop</li>
    <li className='ltr-list-item'>Illustrator</li>
    <li className='ltr-list-item'>Indesign</li>
    <li>בניית חווית משתמש - ux </li>
    <li>פרוטוטייפינג</li>
    <li className='ltr-list-item'>Figma</li>
    <li className='ltr-list-item'>Sketch</li>
    <li>מבוא לכלי אנימציה</li>
    <li >מבוא לוידאו </li>
    <li className='ltr-list-item'>After effects</li>
    <li className='ltr-list-item'>Premiere</li>
                                                        <li >בניית חווית לקוח ומשתמש</li>
                                                        <li>שיקולים עסקיים בעיצוב</li>
                                                        <li>סדנת פרי לנסר</li>
                            </ul>    
                                    </div>
                                    </div>
                        </div>
                        
        </div >)
}
