import React,{useState,useEffect} from 'react'
import '../styles/QuestionAnswerNode.css'
export default ({question,answer,isDefaultOpen,onClose,...rest}) => {
    useEffect(() => {
       
        setIsAnswerShown(isDefaultOpen)
     },[isDefaultOpen])
    
    const [isAnswerShown, setIsAnswerShown] = useState(!!isDefaultOpen)
    
    useEffect(() => {
        if (!isAnswerShown && onClose)
        {
            onClose()
       }
    }, [isAnswerShown])
    return (
        <div {...rest} className="quation-answer-container">
            <div onClick={(e)=>setIsAnswerShown(!isAnswerShown)} className="quation">{question}<i class={`fas fa-arrow-circle-${isAnswerShown?'up':'down'}`}></i></div>
            <div  className={`answer ${isAnswerShown?'show':''}`} dangerouslySetInnerHTML={{__html:answer}} >
        </div>
        </div>
        
    )
}