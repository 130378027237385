import React from 'react'
import '../styles/PromoteBanner.css'
export default () => {
    return (
        <div className="banner">
            <div className="banner-text">
                ?לא עבדת
                <span className="emphasis">!לא שילמת</span>
            </div>
            </div>
    )
}