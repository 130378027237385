import React from 'react'
import '../styles/emailModal.css'
export default ({closeCallBack,hidden,isSuccess,...rest}) => {
    return (<div {...rest} className={`email-modal ${hidden?'hide':''}`}>
        <div className='email-modal-content'>
        <i onClick={(e)=>{closeCallBack(false)}} class="fas fa-times"></i>
            <h3 className='email-modal-title'>{isSuccess? "תודה רבה!":"אנו נורא מתנצלים"}</h3>
           <div className='modal-text'>
            {isSuccess? `עשית את הצעד הראשון בהסבה מקצועית לענף ההיי-טק.  ניצור איתך קשר בהקדם.`:
                    `הייתה שגיאה בשליחת הטופס אנא נסה שנית מאוחר יותר.
                    `}
               </div>
    { isSuccess?<i class="far fa-check-circle"></i>:<i onClick={(e)=>closeCallBack(false)} class="far fa-times-circle"></i>}
       </div>
    </div>)
}