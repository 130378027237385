import React,{useState} from 'react'
import CostumInput from './CostumInput'
import PrivacyModal from './PrivacyModal'
import AccessModal from './AccessModal'
import '../styles/FooterContact.css'
import { handleForm ,sendEmail} from '../utils/form'
import SendMailResultModal from '../Components/SendMailResultModal'



export default (props) => {
    const [isPrivacyHidden, setIsPrivacyHidden] = useState(true)
    const [isAccessHidden, setIsAccessHidden] = useState(true)
    const [isMailSendModalShown,setIsMailModalShown]=useState(false)
    const [isSendFormSuccess,setSendFormSuccess]=useState(false)
    const handleSubmit = async (e) => {
        const form = e.target;
        const isFormValid = await handleForm(e);
        if(isFormValid)
        {
            setSendFormSuccess(await sendEmail(form))
            setIsMailModalShown(true)
            form.reset();
        }
    }
    return (
    
    <div id='contact-hyper-footer' className={`contact-footer  ${props.className}`}>
    <h2>צרו קשר לכל שאלה או לקביעת פגישת ייעוץ (מלאו פרטיכם ונחזור אליכם בהקדם) </h2>
    <SendMailResultModal closeCallBack={setIsMailModalShown} isSuccess={isSendFormSuccess} hidden={!isMailSendModalShown}/>
            <form onSubmit={handleSubmit}>
                <div className="input-container footer">
                    <div></div>
        <CostumInput placeholder='*נא להזין שם תקין' className="footer-input" text="שם מלא" name='name'/>
        <CostumInput placeholder='*נא להזין מספר טלפון'  className="footer-input"  text="טלפון" name='phone'/>
        <CostumInput placeholder='*נא להזין כתובת מייל' className="footer-input" text="אי-מייל" name='email' />
            <button className="sumbit-contact footer">
            <div className="sumbit-contact-inner-box">!דברו איתי</div>
       </button>
            </div>
       
            <div className="agree-checkbox footer">
                    <label className="agree-explain">
                        אני מאשר קבלת עדכונים ומבצעים מהייפראקטיב בכפוף לתקנון.
                         
                        </label>
                <input id='agree' defaultChecked={true} className="checkbox" type="checkbox" name='agree' value='agree promote'/> 
            </div>    
                <a onClick={(e) => setIsPrivacyHidden(false)} className="privacy-policy">מדיניות פרטיות משתמשים</a>
                <a onClick={(e)=>setIsAccessHidden(false)} className="privacy-policy">הצהרת נגישות</a>
        </form>
            <PrivacyModal hidden={isPrivacyHidden} closeCallBack={setIsPrivacyHidden} />
            <AccessModal hidden={isAccessHidden} closeCallBack={setIsAccessHidden}/>
</div>
)}