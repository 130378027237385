import React,{useEffect} from 'react'
import LogoWidget from './DataPageLogo'
import ContactBox from '../FooterContact'
import ContactUs from '../ContactUs'

const title = ' קריירה בבדיקת תוכנה';
export default () => {
        useEffect(() => {
                document.title=title
            },[])
        return (
                <div className="data-page-wrapper">
                <ContactUs className=' data-page-contact'/>

                        <div className="data-page" >
                    
                    <LogoWidget id='qa-data-page-logo' imageSrc={require('../../images/courses-icons/qa_course.png')} title={title}/>
    
                    <h1 className='main-page-title'>קריירה בבדיקת תוכנה</h1>
   <div className='data-section start-section '>
                                <p>
                                        באגים - שגיאות מחשב, תוכנה ותכנון יכולים לגרום לנזקים כלכליים עצומים ובמקרים חמורים במיוחד למוות.    </p>
                                <p>
                                                כך למשל קרה באוקטובר 1999 כאשר החללית האמריקאית Orbiter Climate Mars אבדה בחלל. החקירה גילתה שטעות תוכנה קטנה, שגרמה לשימוש במידות מסויימות לפי יחידות מידה אנגליות במקום יחידות מידה מטריות, גרמה לאובדן החללית ולנזק של 125 מיליון דולר.
                                </p>
                                        <p>
                                                בדומה ב-1996 טיל אריאן בשווי 1 מיליארד דולר של סוכנות החלל האירופאית התרסק דקה לאחר שיגורו עקב באג בתוכנת הניווט של הטיל.
                                        </p>
                                        <p>
                                                ואילו באגים במכונת ההקרנה הרפואית Therac‪-‬25 גרמו למות המטופלים עקב רמת קרינה שגויה.
                                        <br />
                                                <br />
                                                בארצות הברית לבד מוערכים נזקי הבאגים השנתיים בקרוב ל-100 מיליארד דולר.
                                        </p>
                                        <p>
                                                כיום, ובמיוחד בעידן הקורונה, כאשר כל תחומי החיים נשלטים על ידי מערכות ותוכנות מחשב,
                                                ‪תפקיד בודק התוכנה חיוני מאין כמוהו, וקיים ביקוש גבוה לעובדים בתחום‬.
                                        </p>
                                        <p>
                                        חשוב להבין שתהליך הבדיקה והאיכות לא מוגבל רק לשלב הסופי של בדיקת התוכנה המוגמרת -  איש qa מודרני מעורב בכלל מחזור הפרוייקט - החל מתכנון המערכת הנדרשת, הגדרת ממשקי התוכנה ושיטת ההטמעה בארגון, דרך בדיקת מסמכי האפיון, בדיקת מרכיבי תוכנה וחומרה בסמוך לכתיבתם, בדיקות אינטגרציה בין מרכיבי תוכנה וחומרה חדשים וותיקים וסיוע ללקוח בבדיקות קבלה וניהול בדיקות שטח ב-beta sites.
                                        </p>
                                </div>
                    <ContactBox className='mobile-only' />
                    <div className="data-page-content">
                <div className="positions-section data-section">
    <h2 className=' data-subtitle'>תפקידים בתחום</h2>
    <p>
    <p>‬
    בודקי תוכנה מתחילים לרוב כבודקי תוכנה ידניים, כשלעיתים התפקיד מוגדר על ידי החברה כ-״מהנדס בדיקות״.     </p>
    <p>    
                                                        מכאן ניתו להתקדם לתפקיד
      
      ראש צוות בדיקות, מנהל בדיקות ובהמשך לתפקידי ניהול ופרוייקטים שונים.<br />
      קיימים גם תפקידי בדיקות בעלי תת התמחויות כגון בדיקות עומסים, בדיקות אבטחה.<br />
      במקביל קיימים גם תפקידי בדיקות המשיקים לתפקידי הפיתוח כגון מפתח אוטומציה, מפתח תשתיות בדיקות אוטומטיות וראשי צוותים לאוטומציה. בתפקידים אלה השכר גבוה יותר בדרך כלל מאשר בבדיקות ידניות.                                                  
          </p> 
                                                </p> 
                    </div>
                    <div className="prerequisites-section data-section">
    <h2 className='data-subtitle'>קהל יעד ודרישות קדם</h2>
                                                <p>
                                                        תפקידים בתחום נתפסים על ידי רבים כדרך לכניסה קלה יותר לענף עם אפשרות להתפתח בעתיד גם לתפקידי פיתוח.
                                                </p>   
                                                <p>
                                                        יחד עם זאת, יש לדעת  שעם כניסת טכנולוגיות הענן לשימוש נפוץ והמעבר של מוצרי תוכנה לשימוש במודל saas הפכו תפקידי בדיקת התוכנה למורכבים ומאתגרים יותר. בדיקת תוכנה הינה קריירה בפני עצמה, מתגמלת, מעניינת ויציבה. 
                                                </p>
                                                <p>
                                                        בניגוד לעבר תפקידי בדיקות מתגמלים דורשים ידע טכנולוגי רחב, ידע במתודולוגיות qa ובנוסף יחסי אנוש וכישורים בין אישיים מעולים. איש ה-qa נדרש לתפקד בצורה טובה בממשק בין אנשי תוכנה, מנהלים, שיווק ופרוייקטים וצריך לשקף בטחון עצמי ומקצועיות אך גם לנהוג בדיפלומטיות בעת הצורך.
                                                </p>
                                                <p>
                                                דרישות המינימום לבחינת מועמד לתחום הינן אנגלית מצויינת, יכולת תקשורת גבוה, יכולת לימוד עצמי, סקרנות, ביקורתיות ואישיות נעימה. 
עם זאת הרוב המוחלט של התלמידים במסלולי ה-qa אצלנו הינם גם בעלי תואר ראשון עם ממוצע ציונים גבוה.

                                                </p>
   </div>
                    <ContactBox className='mobile-only'/>
                    <div className="training-section data-section">
    <h2 className='data-subtitle'>מסגרת ההכשרות</h2>
                                                <p>
                                                מסלולי ההכשרה בבדיקות תוכנה מועברים במסגרת לימודי בוקר, לימודי ערב ולימודים היברידיים, ונמשכים בין 3‪.‬5-8 חודשים.
    <br />
    מסלולי ההכשרה כוללים כ- 360 שעות לימוד ותרגול אקדמאיות.
    <br />
    הלימודים מתקיימים בקצב מהיר וכוללים כמות גדולה מאוד של שיעורי בית, מבחנים, פרוייקטים ועבודות להגשה.                                              
    </p>  
                                                <p>
                                                מלבד תעודת הסמכה מטעמנו, תלמידים המעוניינים בכך יכולים לגשת למבחן ההסמכה הסטנדרטי בתחום בדיקות התוכנה - istqb ולמבחני הסמכה נוספים של החברות מיקרוסופט ואמזון.
                                                </p>
       </div>
       <div className="prerequisites-section data-section">             
    <h2 className='data-subtitle'>נושאים עיקריים בהכשרות</h2>
    <ul className='subjects-list'>
    <li>מבוא לבדיקות תוכנה</li>
    <li>מבוא למערכות הפעלה</li>
                                                        <li>טכנולוגיות web</li>
                                                        <li>מבוא לרשתות תקשורת</li>
    <li className='ltr-list-item'>Linux</li>
    <li>מבוא לענן azure ו-aws</li>
    <li>בדיקות תוכנה ומתודלוגיות qa</li>
    <li>מערכות לניהול בדיקות - qc‪,‬ jira‪,‬ bugzilla‪…‬</li>
    <li>מסדי נתונים ושפת sql</li>
    <li>בדיקות web ובדיקות mobile</li>
    <li >תכנות בשפת python</li>
    <li className='ltr-list-item'>Selenium</li>
    <li >תכנות אוטומציה</li>
    <li>הכנה למבחני הסמכה</li>
 
                            </ul>    
                                    </div>
                                    </div>
                        </div>
                        
        </div >)
}
