import validator from 'validator'
import emailjs from 'emailjs-com'
import axios from 'axios'
const sendEmail = async (form) => {
    const name = form.children[0].children[1].lastChild.value;
    const phone = form.children[0].children[2].lastChild.value;
    const email = form.children[0].children[3].lastChild.value;
  
    let isValidate = true;
    // const agreeEmails = e.target.children[1].children[1]
    console.log(`https://www.hyperactive.co.il/landing/mobile/formtoemail.php?email=${email}&name=${name}&phone=${phone}", http://www.hyperactive.co.il/landing/mobile/formtoemail.php?email=${email}&name=${name}&phone=${phone}`)
    
    try
    {return await axios.post(`https://www.hyperactive.co.il/landing/mobile/formtoemail.php?email=${email}&name=${name}&phone=${phone}`,
        {
        headers:{'Access-Control-Allow-Origin': '*'}
       
        }).then(() => true).catch((err) => { console.log(err); return true })
    }
   
    catch (err) {
        console.log(err)
    }
//    return await emailjs.sendForm('service_dkxeq97', 'template_or7ldau', form, 'user_LI0ozI1LegTaR8ZH2aLu6').then(value => {
//         return true
//     }).catch(()=>{return false})
}
const handleForm =async (e) => {
    e.preventDefault();
    const inputs = {}
    
     inputs.name = e.target.children[0].children[1].lastChild;
    inputs.phoneNumber = e.target.children[0].children[2].lastChild;
    inputs.email = e.target.children[0].children[3].lastChild;
  
    let isValidate = true;
    inputs.agreeEmails = e.target.children[1].children[1]
   
    for(let inputName in inputs)
    {
       
        inputs[inputName].classList.remove('unvalid')
    }
    if (inputs.name.value.length === 0)
    {
        isValidate=false
        inputs.name.value=''
        inputs.name.classList.add('unvalid')
    }
    if (!(/^0\d([\d]{0,1})([-]{0,1})\d{7}$/.test(inputs.phoneNumber.value)))
    {
        isValidate=false
        inputs.phoneNumber.value=''
        inputs.phoneNumber.classList.add('unvalid')
    }
    if (!validator.isEmail(inputs.email.value))
    {
        isValidate=false
        inputs.email.value=''
        inputs.email.classList.add('unvalid')
    }
    return isValidate
   
   

}
export {handleForm,sendEmail}