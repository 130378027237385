import React from 'react'
import '../styles/CostumInput.css'
export default (props) => {
    return (
        <div className={`costum-input ${props.className}`}>
       
            <label>{props.text}</label>
            <div className="separetor"></div>
            <input name={props.name} placeholder={props.placeholder}  type={props.type||'text'}></input>
                        </div>
    )
}