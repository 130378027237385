import React from 'react' 
import CourseWidget from './CourseWidget'
import '../styles/CoursesWidgetContainer.css'
export default () =>
(<div className="courses-container">
                <CourseWidget
                        id="cyber-data"
                        href="#3"
                        logoSrc={require('../images/courses-icons/cyber_course.png')}
                        title="סייבר ואבטחת מידע |  ethical hacking"
                        href='/cyber-page'
                    />
                    <CourseWidget
                        id="net-securety-data-cloud-manegment"
                        href="#2"
                        logoSrc={require('../images/courses-icons/system_adminisration_course.png')}
            title="ניהול  רשתות ומחשוב | system administration"
            href='/system-admin-page'
        />
                    <CourseWidget
                        id="digital-marketing"
                        href="#3"
                        logoSrc={require('../images/courses-icons/marketing_course.png')}
            title=" שיווק דיגיטלי | seo/social/sales&marketing"
            href='/marketing-page'
                    />
                    <CourseWidget
                        id="QA"
                        href="#3"
                        logoSrc={require('../images/courses-icons/qa_course.png')}
            title="בדיקת תוכנה | qa & automation"
            href='/qa-page'
                    />
                    <CourseWidget
                    id="grhapic-ui-ux"
                    href="#3"
                    logoSrc={require('../images/courses-icons/ui_course.png')}
            title="עיצוב גרפי | ui/ux"
            href='/ui-ux-page'
                    />
                    <CourseWidget
                    id="fullstack-dev"
                    href="#3"
                    logoSrc={require('../images/courses-icons/full_stack_course.png')}
            title="פיתוח תוכנה dot net/java/php | full stack  "
            href='/fullstack-page'
                    />
                    <CourseWidget
                    id="mobile-dev"
                    href="#3"
                    logoSrc={require('../images/courses-icons/mobile_course.png')}
            title="פיתוח אפליקציות מובייל  | ios/android"
            href='/mobile-page'
                    />
                    <CourseWidget
                    id="server-linux-manegment"
                    href="#3"
                    logoSrc={require('../images/courses-icons/embedded_course.png')}
            title="פיתוח מערכות משובצות מחשב  | embedded"
            href='/embedded-page'
                    />
                    
                    
                
                </div>)
                