import React,{useEffect} from 'react'
import LogoWidget from './DataPageLogo'
import ContactBox from '../FooterContact'
import ContactUs from '../ContactUs'

const title = ' קריירה פיתוח תוכנה';
export default () => {
        useEffect(() => {
                document.title=title
            },[])
        return (
                <div className="data-page-wrapper">
                <ContactUs className=' data-page-contact'/>

                        <div className="data-page" >
                    
                    <LogoWidget id='fullstack-data-page-logo' imageSrc={require('../../images/courses-icons/full_stack_course.png')} title={title}/>
    
                    <h1 className='main-page-title'>קריירה בפיתוח תוכנה</h1>
                                <div className='data-section start-section'>
                                        
                                        <p className='section-emphasis'>
                                        קורסי הפיתוח שלנו הינם הטובים בישראל. הקורסים מיועדים עבור מועמדים שמעונינים בהכשרה יסודית וכניסה מהירה לעבודה בפיתוח תוכנה בענף ההיי-טק.
                                                                             <br />
                                        <br />
                                        עם זאת, אם אתה מוכן לעבור הכשרה ארוכה יותר, ומוכן להתחייב מראש לעבוד מספר שנים כמפתח תוכנה,
                                        "<a href='https://talpiot-hitech.com/' target='_blank'  className='underline-emphasis inline-item'>תלפיות היי-טק</a>"
                                        עדיפה עבורך. תלפיות היי-טק מציעה תוכנית הכשרה ארוכה ומעמיקה מאוד של אנשי פיתוח, במגוון גדול של טכנולוגיות ובמודל בו התלמידים לא משלמים כלל שכר לימוד ומתחייבים לעבוד עבור החברה מספר שנים.
                                </p>
                                        <p>
                                                מכל מקצועות ההיי-טק אין ספק שהמקצוע המאתגר ומתגמל ביותר הן כספית והן מבחינת מסלול קריירה הינו מקצוע מפתח התוכנה.
                                        <br />
                                        ללא קשר לכינוי שניתן למשרות על ידי חברות שונות - ״תוכניתן״, ״איש פיתוח״, ״מהנדס תוכנה״, ״ארכיטקט תוכנה״ וכו׳ בבסיס מפתח התוכנה עוסק בכתיבת תוכניות מחשב שנועדו לממש אלגוריתמים לפתרון מגוון בעיות בחיים המעשיים.        
                                                </p>
                                        <p>
                                                קיים מגוון גדול של סביבות הרצה לתוכנת מחשב - תוכנה שרצה בתוך מוצרים embedded, תוכנה שרצה במחשב שולחני/מחשב נייד desktop, תוכנה שרצה במחשבים מרכזיים גדולים main frame , תוכנה שרצה במכשיר טלפון נייד  mobile ועוד.
                                        </p>
                                        <p>
                                                עם זאת תחום פיתוח התוכנה החשוב ביותר כיום הינו פיתוח תוכנה שנעשה בה שימוש באמצעות דפדפן. ברמה הפשוטה יחסית מדובר כמובן באתרי אינטרנט. 
                                                         <br />
                                                         אולם ברמה המתקדמת מדובר במוצרי תוכנה מסובכים ביותר. מערכות כמו פייסבוק, טוויטר, אינסטגרם, skype, zoom ואחרות נדרשות לספק חווית משתמש מושלמת למיליוני משתמשים תחת עומסים יוצאי דופן.
                                                <br />
                                                כמעט כל חברות הסטארט אפ מפתחות בטכנולוגיות ה-web וזהו התחום בו קיים הביקוש הרב ביותר למפתחי תוכנה.
                                                <br />
                                                מעבר לכך זהו גם התחום הדינאמי ביותר, בו נעשה שימוש בשפות ובכלים חדשניים ופורצי דרך.
                                                </p>
                                        <p>
                                                פיתוח ל-web מתחלק באופן גס לפיתוח צד קליינט ‪front end ‬ - חלק התוכנה שמתבצע באופן בלעדי בדפדפן, ולפיתוח צד שרת back end - חלק התוכנה שמתבצע בשרת.
                                        <br />
                                        למפתח השולט הן בפיתוח ה-front end והן בפיתוח ה-back end  קוראים מפתח full stack.        
                                        </p>
                                        <p>
                                                בניגוד לתחומי פיתוח כמו embedded בהם קיימת אחידות גדולה ורוב המפתחים עושים שימוש באותן שפות ואותם כלים, קיימת שונות גדולה מאוד בקרב מפתחי full stack.
                                                         <br />
                                                         אמנם פיתוח צד הקליינט מתבסס על שפות האינטרנט הותיקות - html‪,css,java script ‬ אבל קיים עושר גדול מאוד בספריות פיתוח - react‪,‬ angular‪,‬ vue ואחרות וכל מפתח עושה שימוש ברכיבים שונים. בצד שרת קיימת שונות רבה עוד יותר - שפות כמו node‪.‬js‪, python, java, c#, php, go ‬ יכולות לשמש באופן בלעדי או יחד כשפת הפיתוח העיקרית ומגוון הספריות האפשריות להאצת הפיתוח גם כן עצום. בנוסף קיימות גם אפשרויות מגוונות נוספות בהתאם לענן שאת שירותיו צורכים - aws‪,‬ azure‪,‬ gcp ועוד.
                                        </p>
                                        <p>
                                        מסיבה זו קיימים בתעשית ההדרכה מסלולים שונים ברמות איכות ומקצועיות שונות מאוד - חלק מכנים את עצמם פיתוח ב-java, חלק מכנים את עצמם פיתוח ב-dot net, חלק מכנים את עצמם פיתוח אתרים/פיתוח ב-php וחלק פשוט ״full stack״. כולם בסופו של דבר מכוונים לנגזרת ורמה מסויימת של  ״full stack״.
                                        </p>

                                </div>
                    <ContactBox className='mobile-only' />
                    <div className="data-page-content">
                <div className="positions-section data-section">
    <h2 className=' data-subtitle'>תפקידים בתחום</h2>
    <p>
    התפקיד הראשון בתחום הינו מפתח תוכנה. מפתחי תוכנה מתחילים (junior) בתחום עובדים בדרך כלל בצוות של 4-5 מפתחים תחת ראש צוות. ברוב החברות קיימים מסלולי קידום מקצועיים וניהוליים כאחד. לאחר מספר שנים בתור מפתח senior מקבלים לרוב אחריות מוגברת ועצמאות גבוה יותר בעבודה  או הופכים לראש צוות. בהמשך ניתן להתקדם לניהול מספר צוותים, ראש מחלקה ולבסוף מנהל פיתוח. אנשים בעלי נטיות עסקיות נודדים לאחר מספר שנות פיתוח למגוון תפקידים עסקיים בחברות הגדולות, הדורשים גם רקע והבנה טכנולוגית מעמיקה.    </p> 
                    </div>
                    <div className="prerequisites-section data-section">
    <h2 className='data-subtitle'>קהל יעד ודרישות קדם</h2>
    <p>‬
    כלל תפקידי פיתוח התוכנה דורשים יכולת לוגית גבוה, חשיבה מתמטית טובה מאוד ואנגלית מצויינת.    </p>
    <p>    
    קיים ביקוש עצום למפתחי full stack וחברות מודרניות קולטות מפתחים מצויינים ללא קשר לרקע אקדמי קודם.    </p>
    <p>    
                                                        עם זאת רוב התלמידים במסלולי ה- full stack אצלנו הינם בעלי תואר ראשון בהצטיינות וציון פסיכומטרי 680 ומעלה.
    <br /><br/>
    אם אין לך את הנתונים האלה אבל אתה מאמין ביכולות שלך ובעל רצון עז להיות מפתח תוכנה, הגש מועמדות בכל מקרה.                                                    
    </p>
    <p>    
    
    אנחנו עובדים, כידוע, בשיטת ״לא עבדת לא שילמת״ וכל מועמד עובר תהליכי אבחון ומיון יסודיים בטרם קבלה למסלולי ההכשרה. אם תעבור אותם בהצלחה, הנתונים הסטטיסטיים הראשונים שלך לא ישנו.    </p> 
   </div>
                    <ContactBox className='mobile-only'/>
                    <div className="training-section data-section">
    <h2 className='data-subtitle'>מסגרת ההכשרות</h2>
    <p>
                                                        מסלולי ההכשרה בפיתוח תוכנה לסביבת full stack  מועברים במסגרת לימודי בוקר אינטנסיביים, לימודי ערב ולימודים היברידיים, ונמשכים בין 4-12 חודשים.
        <br />
        מסלולי ההכשרה כוללים כ- 500 שעות לימוד ותרגול אקדמאיות.
        <br />
        הלימודים מתקיימים בקצב מהיר וכוללים כמות גדולה מאוד של שיעורי בית, מבחנים, פרוייקטים ועבודות להגשה.                                               
        </p>  
    
       </div>
       <div className="prerequisites-section data-section">             
    <h2 className='data-subtitle'>נושאים עיקריים בהכשרות</h2>
    <ul className='subjects-list'>
    <li>מבוא לפיתוח תוכנה</li>
    <li className='ltr-list-item'>Html</li>
    <li className='ltr-list-item'>Css </li>
    <li className='ltr-list-item'>Java script</li>
    <li className='ltr-list-item'>JQuery</li>
    <li className='ltr-list-item'>React , redux/mobix</li>
    <li className='ltr-list-item'>Angular</li>
    <li className='ltr-list-item'>Node.js+express</li>
    <li className='ltr-list-item'>php+laravel</li>
    <li className='ltr-list-item'>Java, spring, hibernate</li>
    <li className='ltr-list-item'>c#, dot net:asp.net core, ef</li>
    <li className='ltr-list-item'>python+django</li>
    <li>מבני נתונים ואלגוריתמים</li>
    <li>מסדי נתונים ושפת SQL</li>
    <li >תכנות מבני</li>
    <li>תכנות מוכוון עצמים</li>
    <li className='ltr-list-item'>Multi paradigm programming</li>
                            </ul>    
                                    </div>
                                    </div>
                        </div>
                        
        </div >)
}
