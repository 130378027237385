import React,{useEffect} from 'react'
import LogoWidget from './DataPageLogo'
import ContactBox from '../FooterContact'
import ContactUs from '../ContactUs'

const title = 'קריירה בשיווק דיגיטלי';
export default () => {
        useEffect(() => {
                document.title=title
            },[])
        return (
                <div className="data-page-wrapper">
                <ContactUs className=' data-page-contact'/>

                        <div className="data-page" >
                    
                    <LogoWidget id='marketing-data-page-logo' imageSrc={require('../../images/courses-icons/marketing_course.png')} title={title}/>
    
                    <h1 className='main-page-title'>קריירה בשיווק דיגיטלי</h1>
                                <div className='data-section start-section '>
                                <p>
                                        
                                        תפקידי שיווק, מכירות ופרסום מניעים את הכלכלה העולמית. מהפכת האינטרנט, פייסבוק והרשתות החברתיות גרמה לשינוי מן הקצה לקצה באופן בו מתנהל שיווק ופרסום מודרני.
                                        <br />
                                        תקציבי הפרסום של חברות לאינטרנט עברו מזמן את תקציבי הפרסום בדפוס וברדיו ורק הולכים וגדלים. כמות עצומה של עסקאות מתבצעות באמזון, ebay ובאתרי המכירות הסיניים ואין ספור תפקידי שיווק, מכירות, פרסום, קידום ותכנון חדשים קמו יש מאין.
                                        </p>
                                <p>
                                        קיים ביקוש גבוה לשכירים בתחום השיווק הדיגיטלי בענף ההיי-טק.  בנוסף זהו גם התחום העיקרי בו בוחרים אנשים שכבר יש להם קריירה בתחום אחר, אבל רוצים לייצר לעצמם הכנסה נוספת במשרה חלקית כפרי לנסרים.
                                </p>
                                <p>
                                        בין התפקידים החדשים שצוברים פופולריות וכלולים תחת קטגורית השיווק הדיגיטלי ניתן למנות:
                                </p>
                                <p>
                                        מנהלי מדיה חברתית - עובדים שתפקידם הבלעדי לעקוב אחרי התיחסויות לחברה בשלל הרשתות החברתיות, כתיבת תגובות בשם החברה וניהול משברים תדמיתיים. 
                                </p>
                                <p>
                                        מנהלי תוכן - עובדים שאחראים ליצירת/איסוף, עריכה ופרסום תוכן בכלל ערוצי התוכן של החברה - באתר, בדף הפייסבוק, בדפים הרשמיים ברשתות האחרות וכמובן ב-youtube.
                                </p>
                                <p>
                                        מקדמי אתרים ואפליקציות - עוסקים ב-seo ו-aso.  
                                        עקב הדומיננטיות הגדולה של חיפושים בגוגל כדי להחשף למידע על חברות ושירותים קיימת חשיבות עליונה להתאמת אתרים לאלגוריתם החיפוש של גוגל כמו גם יצירת תנאים נוספים שישפרו את חשיבות דפי האתר מבחינת אלגוריתם הדירוג של גוגל - זו המומחיות של איש ה-seo.
                                       <br/>
                                        Seo=search engine optimization 
                                        <br/>
                                        בדומה קיימת חשיבות גדולה גם להתאמת אפליקציה והחומרים הנלווים לה שמועלים לחנויות האפליקציות של גוגל ואפל לאלגוריתמי החיפוש בחנויות האפליקציות - וזו המומחיות של איש ה-aso.
                                        <br/>
                                        aso=App Store optimization 
                                                <br/>
                                </p>
                                <p>
                                        מנהלי קמפיינים פרסומיים - קיימות אין ספור אפשרויות לפרסום באינטרנט - האפשרויות הפשוטות יותר דומות במידה מסויימת לפרסום כפי שהוא מקובל בטלויזיה - קנייה מרוכזת של מדיה לפי מחיר קבוע (וזו המומחיות של מנהלי רכש מדיה) ואילו האפשרויות המורכבות יותר הינן פרסום ppc או ppa. 
                                        <br/>
                                        ppc=pay per click ppa=pay per action 
                                        <br/>
                                        בפרסום ppc אנחנו מקבלים שטחי פרסום חינם ומשלמים רק כאשר לקוח לוחץ על המודעה ומועבר למידע מפורט יותר. בפרסום ppa אנחנו שוב מקבלים שטחי פרסום חינם ומשלמים רק כאשר הלקוח מבצע פעולה מוסכמת מראש (כמו למשל השארת פרטים, ביצוע רכישה ממשית ועוד). לגוגל יש מערכת מתוחכמת מאוד שכוללת אינספור פרמטרים ומאפשרת התמודדות על שטחי פרסום ומיקום פרסומי באמצעות מכרז. גם לפייסבוק יש מערכת מורכבת למדי עם פרמטרים דומים.
                                        מנהל פרסום ppc ו-ppa מנהל תקציב עצמאי ורב חשיבות כאשר גם ארגונים קטנים יחסית שלא מפרסמים בשום מדיה אחרת לא מוותרים על פרסום בגוגל. מה שהופך את גוגל ופייסבוק לענקי טכנולוגיה בעלי שווי עצום -  גוגל שווה למעלה מטריליון דולר (מיליון מיליונים) ופייסבוק קרוב ל-800 מיליארד דולר.
                                </p>
                                <p>
                                        מומחי ומנהלי רשתות שותפים - שיווק שותפים affiliate marketing הוא אחת מטכניקות השיווק הדיגיטלי האטרקטיביות ביותר שקיימות. על ידי שיתוף מובילי דעה, בעלי קהלי יעד וקהילות שונות ניתן להגדיל את מכירות ורווחיות החברה ללא הוצאות מקדמיות כמעט במודל win win מנצח.
                                </p>
                                        <p>
                                                בוני אתרי קידום מכירות, affiliate 
                                                ודפי נחיתה - על פניו בניית אתרים נראית מקצוע טכנולוגי אולם לצרכי שיווק דיגיטלי בסיסי קיים צורך בדרך כלל בבנית מספר גדול של אתרים קטנים ופשוטים יחסית בזריזות. מערכת wordpress מאפשרת גם לאנשים שאינם מומחים טכנולוגיים להקים אתרים כאלה במהירות.
                                </p>
                                </div>
                    <ContactBox className='mobile-only' />
                    <div className="data-page-content">
                <div className="positions-section data-section">
    <h2 className=' data-subtitle'>תפקידים בתחום</h2>
    <p>
                                                        כישורי והשכלת שיווק דיגיטלי רלוונטיים קודם כל גם לכל בעלי התפקידים המסורתיים בשיווק, מכירות ופרסום שמשדרגים את הביצועים ורמת ההשתכרות שלהם בהתאם.
                                                        <br />
                                                        <br />
                                                        תפקידים חדשים ויעודיים בתחום השיווק הדיגיטלי כוללים:
מנהלי מדיה חברתית, מנהלי תוכן, מקדמי אתרים, מקדמי אפליקציות מובייל, מנהלי רכש מדיה, מנהלי קמפיינים בגוגל ופייסבוק, אנשי affiliate, מומחי ומנהלי רשתות שותפים, בוני אתרי קידום ותוכן דיגיטלי.

                                                        </p> 
                    </div>
                    <div className="prerequisites-section data-section">
    <h2 className='data-subtitle'>קהל יעד ודרישות קדם</h2>
    <p>‬
                                                קיים ביקוש מתמיד לאנשי שיווק, מכירות ופרסום בכל חברה וכל אחד ואחד מהם יפיק תועלת רבה מכישורי שיווק דיגיטלי.
    <br />
    מועמדים שכבר עוסקים בשיווק, מכירות ופרסום מסורתיים הינם קהל היעד האידיאלי לתחום ואין ספק שיפיקו ממנו את התועלת המקסימלית.
                                                    
                                                </p>
                                        
                                                <p>    
                                                מועמדים שלא עסקו בעבר בשיווק/מכירות או פרסום, אך בעלי יכולות אנליטיות ובין אישיות גבוהות, אנגלית טובה ויצירתיות גם כן מהווים מועמדים טובים לתחום.
                                                </p>
    <p>    
    חלק גדול מהעוסקים בתחום השיווק הדיגיטלי בוחרים בו כדי לייצר בו הכנסה נוספת במשרה חלקית כפרי לנסרים. אנשים אלה ממשיכים לעבוד במקצוע הנוכחי שלהם ופותחים במקביל עסק הנותן שירותים בתחום השיווק הדיגיטלי בהיקף משתנה. חלקם בוחרים בהמשך, לאחר גיבוש קהל לקוחות, לפרוש מהמשרה הנוכחית שלהם  או אפילו לאמץ סגנון חיים של digital nomad.    </p>
    <p>    
    אנחנו עובדים, כידוע, בשיטת ״לא עבדת לא שילמת״ וכל מועמד עובר תהליכי אבחון ומיון יסודיים בטרם קבלה למסלולי ההכשרה. לתלמידים המעונינים לעבוד בתחום כפרי לנסרים קיימת מסגרת ״לא עבדת לא שלמת״ יחודית עבורם.
                                                     
    </p> 
   </div>
                    <ContactBox className='mobile-only'/>
                    <div className="training-section data-section">
    <h2 className='data-subtitle'>מסגרת ההכשרות</h2>
    <p>
    מסלולי ההכשרה בשיווק דיגיטלי מועברים במסגרת לימודי בוקר, לימודי ערב ולימודים היברידיים, ונמשכים בין 5-14 חודשים.
                                                        <br />
                                                        מסלולי ההכשרה כוללים סה״כ כ-540 שעות לימוד ותרגול אקדמאיות.
                                                        <br />
                                                        הלימודים מתקיימים בקצב מהיר וכוללים כמות גדולה מאוד של שיעורי בית, מבחנים, פרוייקטים ועבודות להגשה.
                                                        </p>  
                                                <p>
                                                מלבד תעודת הסמכה מטעמנו, תלמידים המעוניינים בכך יכולים לגשת למבחני ההסמכה בתחום של google ו-facebook.
                                                </p>
       </div>
       <div className="prerequisites-section data-section">             
    <h2 className='data-subtitle'>נושאים עיקריים בהכשרות</h2>
                                                <ul className='subjects-list'>
                                                        <li>מבוא לשיווק דיגיטלי</li>
    <li>יסודות בטכנולוגיות web - html ‪&‬ css</li>
    <li>בניית אתרים באמצעות wordpress</li>
    <li className='ltr-list-item'>Seo</li>
    <li className='ltr-list-item'>Aso</li>
    <li>פרסום בגוגל</li>
    <li>פרסום בפייסבוק</li>
    <li className='ltr-list-item'>Google analytics</li>
    <li>קידום ברשתות חברתיות</li>
    <li className='ltr-list-item'>Affiliate marketing</li>
    <li >אמזון ו-ecommerce</li>
    <li className='ltr-list-item'>eBay</li>
    <li >כתיבה יצירתית ושיווקית</li>
    <li>בניית חווית לקוח ומשתמש </li>
    <li >ניהול לקוחות, משברים ופסיכולוגיה יישומית</li>
    <li >סדנת פרי לנסר</li>
                            </ul>    
                                    </div>
                                    </div>
                        </div>
                        
        </div >)
}
